import React, { useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './../App.css';
import translations from '../Localisation/Speaking.json';


function Speaking({ user, serverDateTime }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateFromUrl = searchParams.get('date');

  const languageCodes = {
    english: "en",
    french: "fr",
    spanish: "es",
    german: "de",
    russian: "ru"
  };

  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(new Audio());
  const [audioSrc, setAudioSrc] = useState(null); // Add this line
  const mediaRecorderRef = useRef(null);

  const [recordingTime, setRecordingTime] = useState(0);
  const recordingIntervalRef = useRef(null);

  const recordingTimeoutRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isMarking, setIsMarking] = useState(false);
  const [isFetchingPrompt, setIsFetchingPrompt] = useState(false);
  const [isFetchingFeedback, setIsFetchingFeedback] = useState(false);


  const [userAnswer, setUserAnswer] = useState(null);
  const [aiFeedback, setAIFeedback] = useState(null);

  const [targetPrompt, setTargetPrompt] = useState('');
  const [nativePrompt, setNativePrompt] = useState('');

  useEffect(() => {
    var serverDate = serverDateTime.split('T')[0]
    console.log("serverDate: " + serverDate)

    if (user && user.uid) {
      if (dateFromUrl) {
        if (isDateParamInFuture(dateFromUrl)) {
          navigate('/')
        } else {
          fetchUserTasks(user.uid, dateFromUrl);
        }
      } else {
        fetchUserTasks(user.uid, serverDate);
      }
    }
  }, []);

  const isDateParamInFuture = (someDateString) => {
    const someDate = new Date(someDateString);
    const utcSomeDate = Date.UTC(someDate.getUTCFullYear(), someDate.getUTCMonth(), someDate.getUTCDate());

    const now = new Date(serverDateTime);
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    return utcSomeDate > utcToday;
  };

  const fetchUserTasks = async (uid, date) => {
    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid, date });
      const userTasks = response.data;
      console.log("Retrieved user task:", userTasks);

      if (userTasks.speaking == true) {
        fetchSpeakingAnswer(uid, date)
      } else {
        console.log("Not yet attempted")
        if (dateFromUrl) {
          navigate("/")
        } else {
          fetchPrompts(date)
          setIsLoading(false)
        }
      }

    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    } finally {
      setIsLoading(false);
    }
  };


  const fetchPrompts = async (date) => {
    setIsFetchingPrompt(true);
    try {
      const answerResponse = await axios.post('https://32990hq10c.execute-api.eu-west-2.amazonaws.com/GetTodaysSpeakingPrompt',
        JSON.stringify({
          level: user.languageLevel.toLowerCase(),
          target: user.learningLanguage,
          native: user.websiteLanguage,
          date: `${date.split('-')[1]}-${date.split('-')[2]}`
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(answerResponse.data);
      setNativePrompt(answerResponse.data[user.websiteLanguage])
      setTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      navigate("/error");
    } finally {
      setIsFetchingPrompt(false);
    }
  };


  const fetchSpeakingAnswer = async (uid, date, retries = 0) => {
    setIsFetchingFeedback(true);
    console.log(date)
    try {
      const answerResponse = await axios.post('https://uti5bo79eb.execute-api.eu-west-2.amazonaws.com/GetSpeakingAnswer', JSON.stringify({ uid, date: date.split('T')[0] }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (answerResponse.status === 200) {
        console.log("Processing complete.");
        console.log(answerResponse)
        setUserAnswer(answerResponse.data.answer)
        setAIFeedback(answerResponse.data.feedback)
        setTargetPrompt(answerResponse.data.targetPrompt)
        setNativePrompt(answerResponse.data.nativePrompt)
        fetchAudio();
        setIsLoading(false)
        setIsFetchingFeedback(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Still processing...");
        // Wait for 10 seconds before retrying
        if (retries < 30) { // Limit the number of retries to prevent infinite loop
          setTimeout(() => fetchSpeakingAnswer(uid, date, retries + 1), 10000);
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error("An error occurred:", error.message);
        navigate("/error");
      }
    } finally {
      // setIsFetchingFeedback(false);
    }
  }




  useEffect(() => {
    if (audioBlob) {
      const newSrc = URL.createObjectURL(audioBlob);
      setAudioSrc(newSrc); // Update the audio source state

      // Cleanup function to revoke the URL when the component unmounts or audioBlob changes
      return () => URL.revokeObjectURL(newSrc);
    }
  }, [audioBlob]);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleAudioEnded = () => {
      setIsPlaying(false); // Change button back to "Play"
      setCurrentTime(0); // Reset currentTime to the beginning of the audio
      audio.currentTime = 0; // Also reset the audio element's currentTime to the start
    };

    if (audioBlob) {
      const newSrc = URL.createObjectURL(audioBlob);
      audio.src = newSrc;
      audio.load(); // Force reload of audio to ensure metadata is loaded
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration === Infinity) {
          audio.currentTime = Number.MAX_SAFE_INTEGER;
          audio.ontimeupdate = () => {
            audio.ontimeupdate = null; // Prevent firing this more than once
            setDuration(audio.duration);
            audio.currentTime = 0;
          };
        } else {
          setDuration(audio.duration);
        }
      });
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('ended', handleAudioEnded);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('loadedmetadata', () => setDuration(audio.duration));
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, [audioBlob]);




  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        // Specify the MIME type for WebM format
        const options = { mimeType: 'audio/webm' };
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        let chunks = [];

        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'audio/webm' }); // Ensure the blob type is set to 'audio/webm'
          setAudioBlob(blob);
          setIsRecording(false);
          chunks = [];
          // Clear any timeouts when recording is manually stopped
          if (recordingTimeoutRef.current) {
            clearTimeout(recordingTimeoutRef.current);
          }
        };

        mediaRecorder.start();
        setIsRecording(true);

        // Reset recording time and start updating it every second
        setRecordingTime(0);
        recordingIntervalRef.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime < 30 ? prevTime + 1 : prevTime);
        }, 1000);

        // Automatically stop recording after 30 seconds
        recordingTimeoutRef.current = setTimeout(() => {
          if (mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            stream.getTracks().forEach(track => track.stop());
          }
        }, 30000);
      })
      .catch(err => {
        console.error("Error accessing the microphone", err);
        alert("Error accessing the microphone. Please email us for support.")
      });
  };


  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop()); // Stop the stream's tracks
      // Clear the recording timeout since we are manually stopping before 30 seconds
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
    }
    // Clear the recording interval when the recording is stopped
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
    }
    setRecordingTime(0); // Reset recording time
  };


  const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      // Extract the base64 string; result is in the format: data:<type>;base64,<data>
      resolve(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(blob);
  });

  const handleSubmit = async () => {
    // setIsMarking(true);
    // setIsLoading(true)

    // stopRecording();

    if (!audioBlob) {
      console.error("No audio file selected.");
      return;
    }

    setIsMarking(true);
    setIsLoading(true)

    try {
      const base64String = await convertBlobToBase64(audioBlob);

      // Prepare the body as a JSON string containing the base64String
      const bodyContent = JSON.stringify({ uid: user.uid, base64String, targetPrompt: targetPrompt, nativePrompt: nativePrompt, targetLanguage: user.learningLanguage, feedbackLanguage: user.websiteLanguage });

      console.log(bodyContent)

      console.log(user.learningLanguage)
      console.log(user.websiteLanguage)

      const response = await fetch('https://6tivkqjvgc.execute-api.eu-west-2.amazonaws.com/TriggerSpeakingMarking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set Content-Type to application/json
        },
        body: bodyContent,
      });

      console.log(response)

      if (!response.ok) {
        setIsLoading(false)
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        fetchSpeakingAnswer(user.uid, serverDateTime)
      }

      const result = await response.json();
      console.log(result); // Process the result here
    } catch (error) {

      console.log(error)
      console.error("Error submitting the audio file", error);
      navigate("/error");
    } finally {
      setIsMarking(false);
    }
  };




  function formatTextWithLineBreaks(text) {
    // Ensure text is defined and is a string
    if (typeof text !== 'string') {
      return null; // or return <></> to render nothing in React components
    }

    // Split the text by line breaks and process each line
    return text.split('\n').map((line, index, array) => (
      <React.Fragment key={index}>
        {line.split(/(\*\*[^*]+\*\*)/g).map((part, idx) => {
          // Check if the part is a bold marker
          if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={idx}>{part.slice(2, -2)}</strong>;
          } else {
            return part;
          }
        })}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  }


  const [audioSrc2, setAudioSrc2] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchAudio = async () => {
    setLoading(true);
    setError('');

    try {

      const today = new Date(serverDateTime).toISOString().split('T')[0];
      const dateFromUrl = searchParams.get('date');

      var dateToFind = null
      if (dateFromUrl) {
        dateToFind = dateFromUrl
      } else {
        dateToFind = today
      }

      const response = await fetch('https://qa3j7v3hb1.execute-api.eu-west-2.amazonaws.com/GetUserSpeakingAudio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: user.uid, date: dateToFind }),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      // Assuming the response is a binary audio file
      const blob = await response.blob();
      const reader = new FileReader();

      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        setAudioSrc2(base64data); // This will be your audio data as a base64 URL
      }

    } catch (err) {
      setError('Failed to load audio: ' + err.message);
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };






  if (isLoading || isFetchingPrompt || isMarking || isFetchingFeedback) {
    let message = ""; // Default message
    if (isMarking) {
      message = translations[user.websiteLanguage].ai_feedback;
    } else if (isFetchingFeedback) {
      message = translations[user.websiteLanguage].ai_feedback;
    } else if (isLoading) {
      message = "";
    }
    return (
      <div className="flex items-center justify-center flex-col h-screen w-screen">
        <div className="spinner"></div>
        {(isLoading || isFetchingPrompt || isMarking || isFetchingFeedback) && (
          <div className='font-opensans w-72 text-center mt-4'>{message}</div>
        )}
      </div>
    );
  }



  return (
    <div className="flex flex-col items-center font-opensans mt-24 w-full">
      <div className='flex flex-row items-center w-[896px] max-w-[90vw] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/microphone.svg`}
          alt='an image of a microphone to represent speaking'
        />
        <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].speaking}</span></h2>
      </div>

      <h2 className='font-opensans text-2xl w-[896px] max-w-[90vw] font-bold'>{targetPrompt}</h2>
      <h2 className='font-opensans text-xl opacity-60 w-[896px] max-w-[90vw]'>{nativePrompt}</h2>

      {!userAnswer && !aiFeedback && (
        <>
          <div className="flex flex-col bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
            <div className='flex flex-row items-center'>
              {isRecording
                ?
                <div className='flex flex-row items-center font-opensans'>
                  <button className='flex items-center justify-center font-opensans text-xl font-bold text-navy bg-[#f1f3f4] min-w-12 w-12 h-12 mr-3 rounded-full hover:scale-105 hover:cursor-pointer' onClick={stopRecording}>
                    <svg width="26%" height="26%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <rect width="100" height="100" fill="black" />
                    </svg>
                  </button>
                  {isRecording && (
                    <div>{translations[user.websiteLanguage].recorded_time.replace("{recordingTime}", recordingTime)}</div>
                  )}
                </div>
                :
                <div className='flex flex-col md:flex-row md:items-center font-opensans'>
                  <button className='flex items-center justify-center font-opensans text-xl font-bold text-red bg-[#f1f3f4] w-max px-4 h-12 mr-3 rounded-full hover:scale-105 hover:cursor-pointer' onClick={startRecording}>
                    <svg className="w-4" width="26%" height="26%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50" cy="50" r="50" fill="#e84f5a" />
                    </svg>
                    <div className='text-sm ml-2'>{translations[user.websiteLanguage].record}</div>
                  </button>
                  {!isRecording && !audioBlob && (
                    <div className='mt-4 md:mt-0'>{translations[user.websiteLanguage].instructions}</div>
                  )}

                </div>
              }

              {!isRecording && audioBlob && (
                <>
                  <audio className='w-full flex-0' controls src={audioSrc}>Your browser does not support the audio element.</audio>
                </>
              )}
            </div>

          </div>

          <button onClick={handleSubmit} disabled={!audioBlob || isRecording} className="font-madimi bg-navy text-white px-4 py-2 mt-4 text-xl rounded-md hover:scale-105 disabled:opacity-50">{translations[user.websiteLanguage].submit} &rarr;</button>
        </>
      )}

      {userAnswer && (
        <div className='flex flex-col bg-white text-navy p-6 rounded-2xl m-3 w-[896px] max-w-[90vw] font-opensans'>
          {/* <p>{userAnswer}</p> */}
          <audio className="w-full" controls src={audioSrc2}>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}



      {aiFeedback && (
        <>
          <div className='flex flex-col bg-aiFeedbackBg text-white p-4 sm:p-6 rounded-2xl m-3 mb-20 w-[896px] max-w-[90vw] font-opensans'>
            <p>{formatTextWithLineBreaks(aiFeedback)}</p>
            <div className='w-full h-[1px] bg-white my-10 opacity-50'></div>
            <div className='text-sm opacity-50'>{translations[user.websiteLanguage].ai_disclaimer}</div>
          </div>
        </>
      )}

    </div>
  );
}

export default Speaking;
