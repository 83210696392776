import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from "react-router-dom";
import translations from '../Localisation/ChooseLanguage.json';

import './../Home/Home.css';
import './../App.css';

const countries = [
  { iso: 'en', name: 'English', flagFileName: 'uk.svg' },
  // { iso: 'fr', name: 'French', flagFileName: 'france.svg' },
  { iso: 'es', name: 'Spanish', flagFileName: 'spain.svg' },
  // { iso: 'de', name: 'German', flagFileName: 'germany.svg' },
  // { iso: 'ru', name: 'Russian', flagFileName: 'russia.svg' },
  // { name: 'Italian', flagFileName: 'italy.svg' },
  // { name: 'Portuguese', flagFileName: 'portugal.svg' },
  // { name: 'Mandarin', flagFileName: 'china.svg' },
  // { iso: 'ja', name: 'Japanese', flagFileName: 'japan.svg' }
];



function ChooseLanguage({ user, setUser }) {
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);

  const handleLanguageSelect = async (language) => {
    setShowLoading(true);
    try {
      const response = await manageUser(user?.uid, language);
      console.log(response);
      
      // Ensure there is a response and a data object
      if (response) {
        setUser(response);
  
        // Check each property and navigate accordingly
        if (!response.learningLanguage) {
          navigate("/chooselanguage");
        } else if (!response.websiteLanguage) {
          navigate("/userlanguage");
        } else if (!response.languageLevel) {
          navigate("/chooselevel");
        } else if (!response.setOnboardingSubscription) {
          navigate("/subscribe");
        } else {
          navigate("/");
        }
      } else {
        // If response or response.data is not as expected, handle the case
        console.error('Unexpected response structure:', response);
        navigate("/error");
      }
    } catch (error) {
      // Handle error from manageUser, perhaps show an error message to the user
      console.error('Error managing user:', error);
      navigate("/error");
    } finally {
      // Hide loading indicator in all cases
      setShowLoading(false);
    }
  };
  

  const manageUser = async (uid, isoCode) => {
    console.log(uid);

    try {
      const response = await axios({
        method: 'put',
        url: 'https://qygqv9ukob.execute-api.eu-west-2.amazonaws.com/ManageUser',
        data: {
          uid: uid,
          learningLanguage: isoCode
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log(response);
      return response.data; // This will return the data from the response

    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be handled where the function is called
      navigate("/error");
    }
  };


  return (
    <div className='flex mx-6 min-h-[100dvh] items-center justify-center'>
      {showLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className='flex flex-col'>
          <h2 className='font-opensans text-2xl md:text-3xl font-bold mb-4 md:mb-8 text-center'>{translations[user.websiteLanguage ? user.websiteLanguage : localStorage.getItem('websiteLanguage')].title}</h2>
          <div className='flex flex-row flex-wrap items-center justify-center w-[40rem] max-w-[90vw]'>

            <button onClick={() => handleLanguageSelect('en')}>
              <div className='flex flex-col items-center justify-center border-white border-2 bg-white bg-opacity-50 text-navy hover:text-white m-3 md:m-4 w-32 md:w-44 h-32 md:h-44 rounded-3xl hover:cursor-pointer hover:animated-gradient-bg'>

                <div className='relative h-[4rem] w-[4rem] md:w-[5rem] md:h-[5rem] mx-6 mb-0 md:mb-2'>
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                </div>

                <p className='font-opensans text-lg md:text-xl font-bold'>{translations[user.websiteLanguage ? user.websiteLanguage : localStorage.getItem('websiteLanguage')].english}</p>
              </div>
            </button>

            <button onClick={() => handleLanguageSelect('es')}>
              <div className='flex flex-col items-center justify-center border-white border-2 bg-white bg-opacity-50 text-navy hover:text-white m-3 md:m-4 w-32 md:w-44 h-32 md:h-44 rounded-3xl hover:cursor-pointer hover:animated-gradient-bg'>
                <img className="w-16 md:w-20 mx-6 mb-0 md:mb-2" src={`${process.env.PUBLIC_URL}/flags/spain.svg`} />
                <p className='font-opensans text-lg md:text-xl font-bold'>{translations[user.websiteLanguage ? user.websiteLanguage : localStorage.getItem('websiteLanguage')].spanish}</p>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChooseLanguage;
