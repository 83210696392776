import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useLocation, useNavigate } from 'react-router-dom';
import translations from '../Localisation/Vocabulary.json';


import Flashcard from '../Components/Flashcard';

function Vocabulary({ user, serverDateTime }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateFromUrl = searchParams.get('date');

  const [isLoading, setIsLoading] = useState(true);
  const [showResults, setShowResults] = useState(false);
  // const [user, setUser] = useState(null);
  const [flashcardIndex, setFlashcardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [animate2, setAnimate2] = useState(true);
  const [languageLevel, setLanguageLevel] = useState('A2');
  const [wordsArray, setWordsArray] = useState([]);

  const [answers, setAnswers] = useState(["new", "new", "new", "new", "new", "new", "new", "new", "new", "new"]);
  const [correct, setCorrect] = useState(0);
  const [wrong, setWrong] = useState(0);

  const [animationState, setAnimationState] = useState('enter');

  const [isChangingCard, setIsChangingCard] = useState(true);


  useEffect(() => {
    var serverDate = serverDateTime.split('T')[0]
    console.log("serverDate: " + serverDate)

    if (user && user.uid) {
      if (dateFromUrl) {
        if (isDateParamInFuture(dateFromUrl)) {
          navigate('/')
        } else {
          fetchUserTask(user.uid, dateFromUrl);
          // fetchDataForUser(dateFromUrl);
        }
      } else {
        fetchUserTask(user.uid, serverDate);
        // fetchDataForUser(serverDate);
      }
    }
  }, []);

  const isDateParamInFuture = (someDateString) => {
    const someDate = new Date(someDateString);
    const utcSomeDate = Date.UTC(someDate.getUTCFullYear(), someDate.getUTCMonth(), someDate.getUTCDate());

    const now = new Date(serverDateTime);
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    return utcSomeDate > utcToday;
  };

  const fetchUserTask = async (uid, date) => {
    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid, date });
      const userTask = response.data;

      if (userTask.vocabulary) {
        setShowResults(true);
        const vocabularyArray = userTask.vocabulary.split(',');
        setAnswers(vocabularyArray.map((answer) => answer.trim()));

        fetchDataForUser(date);
      } else {
        if (dateFromUrl) {
          navigate("/")
        } else {
          fetchDataForUser(date);
        }
      }

    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    }
  };


  const fetchDataForUser = async (date) => {
    console.log("Fetching data for user");
    try {
      const response = await axios.post('https://a0n9q9v1kb.execute-api.eu-west-2.amazonaws.com/GetVocabularyList', {
        target: user.learningLanguage,
        native: user.websiteLanguage,
        level: user.languageLevel.toLowerCase(),
        date: date,
      });

      const data = response.data;
      console.log("Words:", data.words);

      // Check if the data has the 'word_1', 'word_2', etc. keys
      if (data.words && typeof data.words === 'object') {
        // Convert the object into an array
        const wordsArray = Object.keys(data.words).map(key => data.words[key]);
        setWordsArray(wordsArray);
      } else {
        console.error('No words data found.');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      navigate("/error");
    }
  };



  const updateUserVocabulary = async (answers) => {
    const uid = user.uid;
    try {
      await axios.put('https://x7p00didl7.execute-api.eu-west-2.amazonaws.com/UpdateUserVocabularyResults', { uid, answers });
      console.log("Vocabulary results updated successfully.");
    } catch (error) {
      console.error("Failed to update vocabulary results:", error);
      navigate("/error");
    } finally {
      setIsLoading(false)
    }
  };

  const goToNextFlashcard = (result) => {
    setIsChangingCard(true)
    setCorrect(prevCorrect => result === "correct" ? prevCorrect + 1 : prevCorrect);
    setWrong(prevWrong => result === "wrong" ? prevWrong + 1 : prevWrong);

    var newAnswers = answers;
    newAnswers[flashcardIndex] = result;
    setAnswers(newAnswers);

    if (flashcardIndex < wordsArray.length - 1) {
      setAnimationState('leave');
    } else {
      console.log("ended");
      setIsLoading(true);
      setShowResults(true);
      updateUserVocabulary(answers);
    }
  };

  useEffect(() => {
    if (animationState === 'leave') {
      const timeoutId = setTimeout(() => {
        setFlashcardIndex((prevIndex) => prevIndex + 1);
        setAnimationState('enter');
        // setIsFlipped(false)

        setTimeout(() => {
          setAnimationState('');
          setIsChangingCard(false)
          // setIsFlipped(false)
        }, 500);
      }, 500); // Adjust this duration as needed for your leave animation
      return () => clearTimeout(timeoutId);
    }
  }, [animationState]);

  useEffect(() => {
    setTimeout(() => {
      setAnimationState('');
      setIsChangingCard(false)
    }, 500);
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 768;

  if (isLoading) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <div className="spinner"></div>
      </div>
    )
  }

  return (
    <div>


      {(wordsArray && wordsArray.length > 0 && showResults && !isLoading) ? (

        <div className='flex flex-col justify-center items-center mx-auto mt-24 w-[896px] max-w-[90vw]'>
          {answers.map((answer, index) => (

            <div className='flex flex-row items-center mb-4 py-4 w-full bg-white rounded-xl' key={index}>
              <div className={`${answers[index] == "correct" ? 'bg-green' : 'bg-red'} flex items-center justify-center font-opensans text-white mx-4 min-w-10 min-h-10 rounded-full`}>
                {(answers[index] == "correct") ? (<>✔</>) : (<>✘</>)}
              </div>

              <div className='mr-4 w-1/2 font-bold font-opensans'>
                {wordsArray[index]?.[user.learningLanguage] && (
                  <>{wordsArray[index][user.learningLanguage]}</>
                )}
              </div>

              <div className='mx-4 w-1/2 font-opensans'>
                {wordsArray[index]?.[user.websiteLanguage] && (
                  <>{wordsArray[index][user.websiteLanguage]}</>
                )}
              </div>
            </div>

          ))}
        </div>
      ) : (
        <>
          <div className='flex flex-col items-center mt-24 bg-primary max-w-[90vw] mx-auto'>

            <div className='flex flex-row items-center w-[896px] max-w-[90vw]'>
              <img
                className="w-6 mr-2"
                src={`${process.env.PUBLIC_URL}/speechBubble.svg`}
              />
              <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].vocabulary}</span></h2>
            </div>

            <div className='z-40 flex flex-row items-center justify-center text-2xl my-4 mt-6 md:my-12 bg-white p-4 rounded-xl max-w-[90vw] w-[90vw] md:w-auto md:min-w-[360px]'>
              <span className='flex flex-row items-center'><div className='font-opensans ml-2 text-green text-xl md:text-2xl font-bold'>{correct}</div><div className='font-opensans mr-6 ml-2 text-green text-xl md:text-2xl'>✔</div></span>
              <span className='flex flex-row items-center'><div className='font-opensans ml-2 text-red text-xl md:text-2xl font-bold'>{wrong}</div><div className='font-opensans mr-6 ml-2 text-red text-xl md:text-2xl'>✘</div></span>
              <span className='flex flex-row items-baseline'><div className='font-opensans ml-2 text-navy text-xl md:text-2xl font-bold'>{10 - correct - wrong}</div><div className='font-opensans mr-2 ml-2 text-navy text-xl md:text-2xl'>{translations[user.websiteLanguage].remaining.toLowerCase()}</div></span>
            </div>

            <div className='flex flex-row items-center justify-center '>
              {!isMobile && (
                <button className='flex items-center justify-center w-24 h-24 rounded-full bg-red text-white text-4xl mr-16 disabled:opacity-30 transition-opacity duration-200'
                  onClick={() => goToNextFlashcard("wrong")} disabled={isChangingCard}>✘</button>
              )}
              {/* {wordsArray.length > flashcardIndex && ( */}
              <>
                <Flashcard
                  front={wordsArray[flashcardIndex]?.[user.learningLanguage] ?? "Loading..."}
                  back={wordsArray[flashcardIndex]?.[user.websiteLanguage] ?? "Loading..."}
                  isFlipped={isFlipped}
                  setIsFlipped={setIsFlipped}
                  animate={animate}
                  setAnimate={setAnimate}
                  setAnimate2={setAnimate2}
                  animationState={animationState}
                />
              </>
              {/* )} */}

              {!isMobile && (
                <button className='flex items-center justify-center w-24 h-24 rounded-full bg-green text-white text-4xl ml-16 disabled:opacity-30 transition-opacity duration-200'
                  onClick={() => goToNextFlashcard("correct")} disabled={isChangingCard}>✔</button>
              )}
            </div>

            {isMobile && (
              <div className='flex flex-row my-12 max-w-[90vw]'>
                <button className='flex items-center justify-center w-24 h-24 rounded-full bg-red text-white text-4xl mr-16 disabled:opacity-30 transition-opacity duration-200'
                  onClick={() => goToNextFlashcard("wrong")} disabled={isChangingCard}>✘</button>
                <button className='flex items-center justify-center w-24 h-24 rounded-full bg-green text-white text-4xl ml-16 disabled:opacity-30 transition-opacity duration-200'
                  onClick={() => goToNextFlashcard("correct")} disabled={isChangingCard}>✔</button>
              </div>
            )}

          </div>
        </>
      )}
    </div>
  );
}

export default Vocabulary;