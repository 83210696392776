// Flashcard.js
import React, { useState, useEffect } from 'react';
import './Flashcard.css';

function Flashcard({ front, back, isFlipped, setIsFlipped, animate, setAnimate, animationState }) {

  const [isFlipClicked, setIsFlipClicked] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
    setIsFlipClicked(true);
  };

  useEffect(() => {
    console.log(animationState);
    if (animationState === 'enter') {
      setIsFlipClicked(false);
      setIsFlipped(false)
    }
  }, [animationState, setIsFlipped]);

  const cardClassName = `card ${isFlipped ? 'flipped' : ''} ${isFlipClicked ? '' : 'not-animated'}`;
  const containerClassName = `container ${animationState}`;


  return (
    <div className={containerClassName}>
      <div className="container" onClick={handleClick}>
        <div className={cardClassName}>
          <div className="front">
            {front}
          </div>
          <div className="back">
            {back}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Flashcard;
