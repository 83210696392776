import React, { useState, useEffect, useRef } from 'react'; // Import React
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import './MobileHome.css';
// import './App.css';
import translations from '../Localisation/Home.json';


function MobileHome({ websiteLanguage }) {

  const goodbyeWords = ['goodbye', 'adiós'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);

  const [bentoLanguage, setBentoLanguage] = useState('en');

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [contentHeight1, setContentHeight1] = useState(0);
  const [contentHeight2, setContentHeight2] = useState(0);
  const [contentHeight3, setContentHeight3] = useState(0);
  const [contentHeight4, setContentHeight4] = useState(0);
  const [contentHeight5, setContentHeight5] = useState(0);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);
  const contentRef5 = useRef(null);

  useEffect(() => {
    // Update the content height when isOpen changes
    setContentHeight1(isOpen1 ? contentRef1.current.scrollHeight : 0);
    setContentHeight2(isOpen2 ? contentRef2.current.scrollHeight : 0);
    setContentHeight3(isOpen3 ? contentRef3.current.scrollHeight : 0);
    setContentHeight4(isOpen4 ? contentRef4.current.scrollHeight : 0);
    setContentHeight5(isOpen5 ? contentRef5.current.scrollHeight : 0);
  }, [isOpen1, isOpen2, isOpen3, isOpen4, isOpen5]);

  useEffect(() => {
    const currentWord = goodbyeWords[currentWordIndex];
    if (displayText === currentWord && !isCompleted) {
      setIsCompleted(true);
      setTimeout(() => {
        setDisplayText("");
        setIsCompleted(false);
      }, 3000);
      return;
    }
    if (isCompleted) return;
    const updateText = () => {
      setDisplayText(prev => currentWord.slice(0, prev.length + 1));
      if (displayText === '') {
        setCurrentWordIndex(prevIndex => (prevIndex + 1) % goodbyeWords.length);
      }
    };
    const intervalId = setInterval(updateText, 50);
    return () => clearInterval(intervalId);
  }, [currentWordIndex, displayText, isCompleted]);

  return (
    <div className="w-screen overflow-hidden">

      <div className="contentContainerHome mt-24">


        <h2 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike text-6xl' : websiteLanguage == 'ru' ? 'text-3xl font-bold' : 'font-lilita text-6xl'} mx-4`}>{translations[websiteLanguage].dailyAI[0]} <span className='text-white animated-gradient-bg px-4 py-1 rounded-xl'>{translations[websiteLanguage].dailyAI[1]}</span> {translations[websiteLanguage].dailyAI[2]}</h2>

        <h2 className='font-opensans text-lg mt-6 mb-8 font-bold mx-4'>{translations[websiteLanguage].practiceSkills}</h2>

        <Link to="/signin">
          <button className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans font-bold' : 'font-madimi'} text-white px-4 py-2 text-xl rounded-md hover:scale-105 bg-navy`}>{translations[websiteLanguage].getStarted}</button>
        </Link>

        <div className='flex flex-row mt-8 w-screen justify-center px-8 mb-8'>
          <div className='flex flex-col items-center w-1/6 mx-6'>
            {/* <img className="w-24 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} alt='homeFlag' /> */}
            <div onClick={() => setBentoLanguage("en")} className='relative ' style={{ height: '4rem', width: '4rem' }}>
              <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
              <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
            </div>
            <p className='font-opensans text-sm font-bold'>{translations[websiteLanguage].english}</p>
          </div>


          {/* <div className='flex flex-col items-center w-1/6 mx-4'>
            <img className="w-24 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/france.svg`} alt='homeFlag' />
            <p className='font-opensans text-sm font-bold'>{translations[websiteLanguage].french}</p>
          </div> */}
          <div className='flex flex-col items-center w-[4rem] h-[4rem] mx-6'>
            <img onClick={() => setBentoLanguage("es")} className="w-[4rem] h-[4rem] hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/spain.svg`} alt='homeFlag' />
            <p className='font-opensans text-sm font-bold'>{translations[websiteLanguage].spanish}</p>
          </div>
          {/* <div className='flex flex-col items-center w-1/6 ml-4'>
            <img className="w-24 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/germany.svg`} alt='homeFlag' />
            <p className='font-opensans text-sm font-bold'>{translations[websiteLanguage].german}</p>
          </div> */}
        </div>

        {/* <div className='flex flex-row mb-8 mt-2 justify-center w-screen px-8'>
          <div className='flex flex-col items-center w-1/6 mr-4'>
            <img className="w-24 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/russia.svg`} alt='homeFlag' />
            <p className='font-opensans text-sm font-bold'>{translations[websiteLanguage].russian}</p>
          </div>
          <div className='flex flex-col items-center w-1/6 ml-4'>
            <img className="w-24 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/japan.svg`} alt='homeFlag' />
            <p className='font-opensans text-sm font-bold'>{translations[websiteLanguage].russian}</p>
          </div>
        </div> */}

        <div className="mobileBentoBox">
          <div className="mobileBentoBoxRow">
            <div className="mobileBentoElement mobileBentoElementLeft mobileBentoElementWide mobileBentoYellow">
              <p className={`${websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[websiteLanguage].vocabulary}</p>
              <p className="mobileBentoElementDetail">{bentoLanguage == 'en' ? '8 / 10 words correct' : '8 / 10 palabras correctas'}</p>
            </div>
            <div className="mobileBentoElement mobileBentoElementRight mobileBentoElementStandard mobileBentoNavy">
              <p className={`${websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[websiteLanguage].listening}</p>
              <p className="mobileBentoElementDetail">{bentoLanguage == 'en' ? 'An audio clip about food and cooking' : 'Un clip de audio sobre comida y cocina'}</p>
            </div>
          </div>
          <div className="mobileBentoBoxRow">
            <div className="mobileBentoElement mobileBentoElementLeft mobileBentoElementStandard mobileBentoNavy">
              <p className={`${websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[websiteLanguage].reading}</p>
              <p className="mobileBentoElementDetail">{bentoLanguage == 'en' ? 'A text about cats' : 'Un texto sobre gatos'}</p>
            </div>
            <div className="mobileBentoElement mobileBentoElementRight mobileBentoElementWide mobileBentoYellow">
              <p className={`${websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[websiteLanguage].speaking}</p>
              <p className="mobileBentoElementDetail">{bentoLanguage == 'en' ? 'What is your favorite season and why?' : '¿Cuál es tu estación favorita y por qué?'}</p>
            </div>
          </div>
          <div className="mobileBentoBoxRow">
            <div className="mobileBentoElement mobileBentoElementLeft mobileBentoElementWide mobileBentoYellow">
              <p className={`${websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[websiteLanguage].writing}</p>
              <p className="mobileBentoElementDetail">{bentoLanguage == 'en' ? 'Describe a place you would like to visit' : 'Describe un lugar que te gustaría visitar'}</p>
            </div>
            <div className="mobileBentoElement mobileBentoElementRight mobileBentoElementStandard mobileBentoNavy">
              <p className={`${websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[websiteLanguage].culture}</p>
              <p className="mobileBentoElementDetail">{bentoLanguage == 'en' ? 'The British Royal Family' : 'Viñedos y vinificación españoles'}</p>
            </div>
          </div>
        </div>
        <div className='mobileBentoBoxDepth'></div>

        <div className='font-opensans text-2xl font-bold mt-12'>{translations[websiteLanguage].sayToDecisionFatigue[0]} <span className='font-robotoMono text-white animated-gradient-bg px-4 py-2 rounded-xl'>{displayText}</span></div>
        <div className='font-opensans text-2xl font-bold mt-2'>{translations[websiteLanguage].sayToDecisionFatigue[1]}</div>
        <div className='font-opensans text-sm mt-4 font-bold px-6'><span className='font-madimi font-normal'>{translations[websiteLanguage].guidesDaily[0]}</span> {translations[websiteLanguage].guidesDaily[1]}</div>
        {/* <div className='font-opensans text-xl mt-12 mb-8 font-bold'>A convenient way to keep every skill consistent</div> */}

        <div className='flex flex-col items-center justify-between w-[90%] my-12 p-6 text-center border-white border-2 bg-white bg-opacity-50 rounded-3xl overflow-hidden'>
          <div className='flex flex-row justify-between w-full mb-8'>
            <div className='flex flex-col items-center w-3/12'>
              <img
                className="w-12"
                src={`${process.env.PUBLIC_URL}/speechBubble.svg`}
                alt='an image of _'
              />
              <div className='font-opensans text-sm font-bold mt-2'>{translations[websiteLanguage].vocabulary}</div>
            </div>
            <div className='flex flex-col items-center w-3/12'>
              <img
                className="w-12"
                src={`${process.env.PUBLIC_URL}/ear.svg`}
                alt='an image of _'
              />
              <div className='font-opensans text-sm font-bold mt-2'>{translations[websiteLanguage].listening}</div>
            </div>
            <div className='flex flex-col items-center w-3/12'>
              <img
                className="w-12"
                src={`${process.env.PUBLIC_URL}/book.svg`}
                alt='an image of _'
              />
              <div className='font-opensans text-sm font-bold mt-2'>{translations[websiteLanguage].reading}</div>
            </div>
          </div>
          <div className='flex flex-row justify-between w-full'>
            <div className='flex flex-col items-center w-3/12'>
              <img
                className="w-12"
                src={`${process.env.PUBLIC_URL}/microphone.svg`}
                alt='an image of _'
              />
              <div className='font-opensans text-sm font-bold mt-2'>{translations[websiteLanguage].speaking}</div>
            </div>
            <div className='flex flex-col items-center w-3/12'>
              <img
                className="w-12"
                src={`${process.env.PUBLIC_URL}/paper_and_pencil.svg`}
                alt='an image of _'
              />
              <div className='font-opensans text-sm font-bold mt-2'>{translations[websiteLanguage].writing}</div>
            </div>
            <div className='flex flex-col items-center w-3/12'>
              <img
                className="w-12"
                src={`${process.env.PUBLIC_URL}/globe.svg`}
                alt='an image of _'
              />
              <div className='font-opensans text-sm font-bold mt-2'>{translations[websiteLanguage].culture}</div>
            </div>
          </div>
        </div>


        {/* <div>A bit about AI, GPT, OpenAI</div> */}
        {/* <div className='font-opensans text-2xl font-bold mt-4'>
          {translations[websiteLanguage].poweredBy[0]}
          <span className='font-robotoMono text-white animated-gradient-bg px-4 mx-2 py-2 rounded-xl'>{translations[websiteLanguage].poweredBy[1]}</span>
          {translations[websiteLanguage].poweredBy[2]}
        </div> */}
        <img
          className="w-[260px] max-w-[90vw] mt-12"
          src={`${process.env.PUBLIC_URL}/powered-by-openai-badge-filled-on-dark.svg`}
        />
        <div className='font-opensans text-sm mt-4 mb-12 px-6 font-bold'>
          {translations[websiteLanguage].allContentGenerated}
        </div>







        <div className='flex flex-col items-center mt-12 justify-center w-screen'>
          <div className='relative w-[90%] bg-white border-4 rounded-2xl'>
            <h3 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-4`}>{translations[websiteLanguage].free}</h3>

            <p className='font-opensans mt-4'>{translations[websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].listening}</p><
              p className='font-opensans my-4'>{translations[websiteLanguage].reading}</p>
          </div>

          <div className='relative w-[90%] mt-12 bg-white border-orange border-4 rounded-2xl'>
            <div className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans' : 'font-madimi'} absolute bg-orange top-0 w-max left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-2 rounded-lg`}>
              <p>{translations[websiteLanguage].lessThanADay}</p>
            </div>
            <h3 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[websiteLanguage].premiumMonthly}</h3>

            <p className='font-opensans mt-4'>{translations[websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].listening}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].reading}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].speaking} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].writing} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans my-4'>{translations[websiteLanguage].culture}</p>
          </div>

          <div className='relative w-[90%] mt-12 bg-white border-4 rounded-2xl'>
            <div className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans' : 'font-madimi'} absolute animated-gradient-bg text-white top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-2 rounded-lg`}>
              <p>{translations[websiteLanguage].fiftyPercentOff}</p>
            </div>
            <h3 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[websiteLanguage].premiumYearly}</h3>

            <p className='font-opensans mt-4'>{translations[websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].listening}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].reading}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].speaking} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].writing} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans my-4'>{translations[websiteLanguage].culture}</p>
          </div>
        </div>

        <Link to="/signin" className='my-20'>
          <button className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans font-bold' : 'font-madimi'} text-white px-4 py-2 text-xl rounded-md hover:scale-105 bg-navy`}>{translations[websiteLanguage].getStarted}</button>
        </Link>


        <a className='w[250px] h-[54px] mb-6' href="https://www.producthunt.com/posts/bentolingo-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-bentolingo&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=453447&theme=light" alt="bentolingo - Your&#0032;daily&#0032;AI&#0032;language&#0032;bento&#0032;box | Product Hunt" /></a>


        <div className='font-opensans my-20 text-sm opacity-70 px-6'>
          {/* <div>{translations[websiteLanguage].bentolingoPoweredBy}</div> */}
          <div><span className='font-madimi font-normal'>{translations[websiteLanguage].bentolingoPoweredBy[0]}</span> {translations[websiteLanguage].bentolingoPoweredBy[1]}</div>
          <div className='mt-4'>&copy; <span className='font-madimi font-normal'>bentolingo</span> 2024</div>
        </div>


      </div>

    </div>
  );
}

export default MobileHome;
