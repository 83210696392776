import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';

function TermsOfService() {

  return (

    <div className="flex flex-col items-center font-opensans mt-24 w-screen">


      <div className='flex flex-row items-center w-[896px] max-w-[90%] mb-4'>
        <h2 className='font-madimi text-2xl'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>Terms Of Service</span></h2>
      </div>

      <div className="flex flex-col border-white border-2 text-md bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        <div className='text-xl font-bold mb-4'>Last Updated: 16th April 2024</div>

        <p>These terms of service outline the rules and regulations for the use of bentolingo, located at <a href="https://bentolingo.ai">https://bentolingo.ai</a>.</p>
        <p>Please read these terms of service carefully before using bentolingo.</p>
        <p>Your access to and use of bentolingo is conditioned on your acceptance of and compliance with these terms. These terms apply to all visitors, users, and others who access or use bentolingo.</p>


        <div className='font-bold text-xl mt-4'>Licence</div>
        <p>Unless otherwise stated, bentolingo and/or its licensors own the intellectual property rights for all material on bentolingo.
          All intellectual property rights are reserved. You may access this from https://bentolingo.ai for your own personal use subjected to restrictions set in these terms and conditions.</p>
        <p className='mt-2'>You must not:</p>
        <ul class="list-disc pl-6">
          <li>Republish material from bentolingo</li>
          <li>Sell, rent or sub-license material from bentolingo</li>
          <li>Reproduce, duplicate or copy material from bentolingo</li>
          <li>Redistribute content from bentolingo</li>
          <li>Embed bentolingo.ai or any content from bentolingo on another website, for example via iFrames</li>
        </ul>


        <div className='font-bold text-xl mt-4'>Your Privacy</div>
        <p>Please read our Privacy Policy.</p>

        <div className='font-bold text-xl mt-4'>Subscription</div>
        <p>Some parts of bentolingo are only available to subscribers, who are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis ("billing cycle"). Billing cycles are set on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a subscription.</p>
        <p>At the end of each billing cycle, your subscription will automatically renew under the exact same conditions unless you cancel it or we cancel it. You may cancel your subscription renewal through your account management page (accessed from the Settings page).</p>
        <p>A valid payment method, as accepted by Stripe (our payment provider) is required to process the payment for your subscription.</p>
        <p>Should automatic billing fail to occur for any reason, your subscription will be cancelled.</p>

        <div className='font-bold text-xl mt-4'>Fee Changes</div>
        <p>bentolingo reserves the right to change subscription fees at any time. Any changes to fees will be communicated at least 30 days in advance to you prior to such changes taking effect.</p>

        <div className='font-bold text-xl mt-4'>Termination</div>
        <p>You can delete your account at any time from the Settings page.</p>
        <p>bentolingo may terminate your account for any reason, and at any time.</p>


        <div className='font-bold text-xl mt-4'>Refund Policy</div>
        <p>In the event that you delete your account, or if bentolingo terminates your account, you understand and agree that you will receive no refund or compensation of any kind.</p>

        <div className='font-bold text-xl mt-4'>AI</div>
        <p>bentolingo contains material generated by artificial intelligence (AI) from OpenAI. AI can make mistakes and produce incorrect or misleading information. You understand and accept that bentolingo is not responsible for any errors or incorrect information.</p>

        <div className='font-bold text-xl mt-4'>Changes</div>
        <p>We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

        <div className='font-bold text-xl mt-4'>Contact Us</div>
        <p>If you have any questions about these terms, please contact us at <a className='underline' href="mailto:bentolingoai@gmail.com">bentolingoai@gmail.com</a>.</p>


      </div>




    </div>
  );
}

export default TermsOfService;
