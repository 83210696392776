import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';

function Error() {

  return (

    <div className="flex flex-col items-center font-opensans mt-24 w-screen">


      <div className='flex flex-row items-center w-[896px] max-w-[90%] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/robot.svg`}
          alt='an image of a robot'
        />
        <h2 className='font-madimi text-2xl'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>Oops...</span></h2>
      </div>

      <div className="flex flex-col border-white border-2 text-md bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        <div className='text-xl font-bold'>An unknown error occured</div>
        <div className='text-md mt-4'>Please <Link className="underline" to="/">click here</Link> to return home and try again.</div>
        <div className='text-md mt-4'>If you have time, could you please <a className='underline' href="mailto:bentolingoai@gmail.com">send us an email</a> to let us know what you were doing when things went wrong?</div>
      </div>




    </div>
  );
}

export default Error;
