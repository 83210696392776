import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import translations from '../Localisation/Reading.json';


function Reading({ user, serverDateTime }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateFromUrl = searchParams.get('date');

  const [isLoading, setIsLoading] = useState(true);

  const [questions, setQuestions] = useState([]);
  const [text, setText] = useState('');
  const [targetPrompt, setTargetPrompt] = useState('');
  const [nativePrompt, setNativePrompt] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [animationState, setAnimationState] = useState('enter');
  const [userAnswers, setUserAnswers] = useState([]);
  const [taskComplete, setTaskComplete] = useState(false);

  const languageCodes = {
    english: "en",
    french: "fr",
    spanish: "es",
    german: "de",
    russian: "ru",
    japanese: "jp"
  };

  useEffect(() => {
    var serverDate = serverDateTime.split('T')[0]
    console.log("serverDate: " + serverDate)

    if (user && user.uid) {
      if (dateFromUrl) {
        if (isDateParamInFuture(dateFromUrl)) {
          navigate('/')
        } else {
          fetchUserTasks(user.uid, dateFromUrl);
        }
      } else {
        fetchUserTasks(user.uid, serverDate);
      }
    }
  }, []);

  const isDateParamInFuture = (someDateString) => {
    const someDate = new Date(someDateString);
    const utcSomeDate = Date.UTC(someDate.getUTCFullYear(), someDate.getUTCMonth(), someDate.getUTCDate());

    const now = new Date(serverDateTime);
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    return utcSomeDate > utcToday;
  };



  const fetchUserTasks = async (uid, dateWithYear) => {

    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid, date: dateWithYear });
      const userTasks = response.data;
      console.log("Retrieved user task:", userTasks);

      if (userTasks.reading != null) {
        setTaskComplete(true)
        var userParts = userTasks.reading.split(',')
        var level = userParts[userParts.length - 2]
        var targetLanguage = userParts[userParts.length - 1]
        var answerParts = userParts[0] + userParts[1] + userParts[2]
        setUserAnswers(answerParts)
        fetchData(dateWithYear, level, targetLanguage);
      } else {
        console.log("Not yet attempted")
        if (dateFromUrl) {
          navigate("/")
        } else {
          fetchData(dateWithYear, user.languageLevel, user.learningLanguage);
        }
      }

    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    }
  };



  const fetchData = async (date, level, targetLanguage) => {
    try {

      console.log(languageCodes[user.learningLanguage.toLowerCase()]);
      console.log(date);

      console.log(targetLanguage);

      var monthAndYear = date.split('-')[1] + '-' + date.split('-')[2]

      const response = await axios.post('https://ut41cjl9z9.execute-api.eu-west-2.amazonaws.com/GetReadingContent', {
        date: monthAndYear,
        targetLanguage: targetLanguage,
        level: level
      });

      console.log(response.data)

      if (response.data && response.data.question1) {
        setText(response.data.text);
  
        const questionsArray = ['question1', 'question2', 'question3'].map((key) => {
          // Create the options in the correct order
          const orderedOptions = [];
          const correctOption = { text: response.data[key].correct, correct: true };
          const incorrectOptions = response.data[key].incorrect.map((text) => ({ text }));
          const allOptions = [correctOption, ...incorrectOptions];
          
          // Use the order to push options into the orderedOptions array
          response.data[key].order.forEach((index) => {
            orderedOptions.push(allOptions[index]);
          });
  
          // Assign the option letters (A, B, C) according to the new order
          orderedOptions.forEach((option, index) => {
            option.option = String.fromCharCode(65 + index); // Converts 0 to 'A', 1 to 'B', 2 to 'C', etc.
          });
  
          return {
            question: response.data[key].text,
            options: orderedOptions
          };
        });
  
        setQuestions(questionsArray);
      } else {
        console.log('No content found'); // Handle no content found
      }

      // Assuming the Lambda returns an item with a text and questions structure
      // if (response.data.item !== `No content found for ${date}.`) {
      //   setText(response.data.text);
      //   setQuestions(JSON.parse(response.data.questions));
      //   setTargetPrompt(response.data.item[languageCodes[user.learningLanguage.toLowerCase()]])
      //   setNativePrompt(response.data.item[languageCodes[user.websiteLanguage.toLowerCase()]])
      //   setUserAnswers(Array(JSON.parse(response.data.item.Questions).length).fill(null));
      // } else {
      //   console.log(response.data.item); // Handle no content found
      // }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      // navigate("/error");
    } finally {
      var monthAndYear = date.split('-')[1] + '-' + date.split('-')[2]
      getReadingPrompt(monthAndYear)
      // setIsLoading(false);
    }
  };


  const getReadingPrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://fi89g04k25.execute-api.eu-west-2.amazonaws.com/GetReadingPrompt',
        JSON.stringify({
          date: dateToFind,
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setNativePrompt(answerResponse.data[user.websiteLanguage])
      setTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setNativePrompt("Unable to fetch prompt")
      setTargetPrompt("Unable to fetch prompt")
    } finally {
      setIsLoading(false);
    }
  };

  


  useEffect(() => {
    if (animationState === 'leave') {
      const timeoutId = setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setAnimationState('enter');
        if (currentQuestionIndex == questions.length - 1) {
          setTaskComplete(true)
        }
      }, 500); // Duration of the leave animation
      return () => clearTimeout(timeoutId);
    }
  }, [animationState]);

  const handleOptionClick = (optionIndex) => {
    const isCorrect = questions[currentQuestionIndex].options[optionIndex].correct;
    const updatedQuestions = questions.map((question, qIndex) => {
      if (qIndex === currentQuestionIndex) {
        const updatedOptions = question.options.map((option, oIndex) => ({
          ...option,
          selected: oIndex === optionIndex,
          showStatus: true
        }));
        return { ...question, options: updatedOptions, answered: true };
      }
      return question;
    });

    setQuestions(updatedQuestions);

    var temp = userAnswers
    temp[currentQuestionIndex] = optionIndex
    setUserAnswers(temp)

    if (currentQuestionIndex == questions.length - 1) {
      // setTaskComplete(true)
      updateUserReadingResults()
    }

    setAnimationState('leave');
  };




  const updateUserReadingResults = async () => {
    const uid = user.uid; // Assuming 'user' state holds the current Firebase user object

    var tempAnswers = userAnswers
    tempAnswers.push(user.languageLevel)
    tempAnswers.push(user.learningLanguage)
    setUserAnswers(tempAnswers)

    try {
      await axios.post('https://6xptlyuotd.execute-api.eu-west-2.amazonaws.com/UpdateUserReadingResults', { uid, userAnswers });
      console.log("Vocabulary results updated successfully.");
      // Handle post-update actions here (e.g., show results, redirect, etc.)
    } catch (error) {
      console.error("Failed to update vocabulary results:", error);
      navigate("/error");
    } finally {
      setIsLoading(false)
    }
  };

  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 768;



  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-col h-screen w-screen">
        <div className="spinner"></div>
      </div>
    );
  }


  return (
    <div className="flex flex-col items-center font-opensans mt-24">

      <div className='flex flex-row items-center w-[896px] max-w-[90vw] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/book.svg`}
          alt='an image of a book to represent reading'
        />
        <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].reading}</span></h2>
      </div>
      <h2 className='font-opensans text-2xl w-[896px] max-w-[90vw] font-bold'>{targetPrompt}</h2>
      <h2 className='font-opensans text-xl opacity-60 w-[896px] max-w-[90vw]'>{nativePrompt}</h2>

      <div className="bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
        <div className='leading-relaxed'>{text}</div>
      </div>

      <div className="listening-container">
        <div className={`flex flex-col bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw] question ${animationState}`}>
          {((questions.length > 0 && currentQuestionIndex < questions.length) && !taskComplete) ? (
            <div key={currentQuestionIndex}>
              <h2 className='text-lg font-bold'>{questions[currentQuestionIndex].question}</h2>
              {questions[currentQuestionIndex].options.map((option, oIndex) => (
                <div className='flex flex-row items-center mt-6' key={oIndex}>
                  <button
                    key={oIndex}
                    className={`flex items-center justify-center font-opensans text-xl font-bold text-white w-12 h-12 mr-3 rounded-lg hover:scale-105 hover:cursor-pointer bg-navy`}
                    onClick={() => handleOptionClick(oIndex)}
                    disabled={questions[currentQuestionIndex].answered}
                  >
                    {option.option}
                  </button>
                  {option.text}
                </div>
              ))}
            </div>
          ) : (
            <div>
              {(() => {
                const correctCount = questions.reduce((acc, question, qIndex) => {
                  const correctOption = question.options.find(option => option.correct);
                  const correctAnswerText = correctOption ? correctOption.text : "";
                  let userAnswerText = "";
                
                  if (userAnswers[qIndex] !== undefined && question.options[userAnswers[qIndex]]) {
                    userAnswerText = question.options[userAnswers[qIndex]].text;
                  }
                  
                  return acc + (userAnswerText === correctAnswerText ? 1 : 0);
                }, 0);
                
                return <div>{translations[user.websiteLanguage].score.replace("{correctCount}", correctCount)}</div>;
              })()}

              {questions.map((question, qIndex) => {
                const correctOption = question.options.find(option => option.correct);
                const correctAnswerText = correctOption ? correctOption.text : "No correct answer";
                const userAnswerText = userAnswers[qIndex] !== undefined ? question.options[userAnswers[qIndex]].text : "No answer provided";

                return (
                  <div className='flex flex-row items-center mt-6' key={qIndex}>
                    {userAnswerText === correctAnswerText ? (
                      <div className={`flex items-center justify-center text-white ${!isMobile ? 'w-12 h-12 min-w-12 min-h-12 text-2xl' : 'w-8 h-8 min-w-8 min-h-8 text-lg'} rounded-full mr-4 bg-green`}>✔</div>
                    ) : (
                      <div className={`flex items-center justify-center text-white ${!isMobile ? 'w-12 h-12 min-w-12 min-h-12 text-2xl' : 'w-8 h-8 min-w-8 min-h-8 text-lg'} rounded-full mr-4 bg-red`}>✘</div>
                    )}
                    <div className='flex flex-col'>
                      <div className='text-lg font-bold'>{question.question}</div>
                      <div>You answered: {userAnswerText}</div>
                      <div>Correct answer: <span className='font-bold'>{correctAnswerText}</span></div>
                    </div>
                  </div>
                );
              })}
            </div>

          )}
        </div>
      </div>

      {/* <div className='text-sm opacity-50 mt-2'>The above content is provided by OpenAI's GPT AI, and verified by a real human.</div> */}


    </div>
  );
}

export default Reading;
