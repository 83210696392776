import React, { useState, useEffect } from 'react'; // Import React and useEffect
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import { initFirebase } from '../utils/firebase';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import './../App.css';
import UTCCountdown from './UTCCountdown';
import translations from '../Localisation/Header.json';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OtacgJYVWdaEBPsh6I9pu3mWyYenx2YqG4WgeZI03e4g9yoE8UfvNKbiqEgywHUKgBD0e2O34OhEA3vacAIiDf500OeFmYRlw');


function Header({ signedIn, user, websiteLanguage, setWebsiteLanguage, serverDateTime }) {

  useEffect(() => {
    const savedLanguage = localStorage.getItem('websiteLanguage');
    if (savedLanguage) {
      setWebsiteLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('websiteLanguage', websiteLanguage);
  }, [websiteLanguage]);

  const languagesInEnglish = [
    { iso: 'en', name: 'English', imageFileName: 'uk.svg' },
    { iso: 'fr', name: 'French', imageFileName: 'france.svg' },
    { iso: 'es', name: 'Spanish', imageFileName: 'spain.svg' },
    { iso: 'de', name: 'German', imageFileName: 'germany.svg' },
    { iso: 'ru', name: 'Russian', imageFileName: 'russia.svg' },
  ]
  const userLanguageFlag = languagesInEnglish.find(lang => lang.iso === user?.learningLanguage)?.imageFileName ?? 'uk.svg';

  const levelIcons = [
    { name: 'Beginner', imageFileName: 'seedling.svg' },
    { name: 'Intermediate', imageFileName: 'potted_plant.svg' },
    { name: 'Advanced', imageFileName: 'evergreen.svg' }
  ]
  const userLevelIcon = levelIcons.find(level => level.name === user?.languageLevel)?.imageFileName ?? 'seedling.svg';

  const languages = [
    { iso: 'en', name: 'English', flagName: 'uk.svg' },
    { iso: 'zh', name: '普通话', flagName: 'china.svg' },
    { iso: 'es', name: 'Español', flagName: 'spain.svg' },
    { iso: 'hi', name: 'हिन्दी', flagName: 'india.svg' },
    { iso: 'ar', name: 'العربية', flagName: 'arabic.svg' },
    { iso: 'pt', name: 'Português', flagName: 'portugal.svg' },
    { iso: 'ru', name: 'Русский', flagName: 'russia.svg' },
    { iso: 'ja', name: '日本語', flagName: 'japan.svg' },
    { iso: 'fr', name: 'Français', flagName: 'france.svg' },
    { iso: 'de', name: 'Deutsch', flagName: 'germany.svg' },
    { iso: 'ko', name: '한국어', flagName: 'korea.svg' },
    { iso: 'tr', name: 'Türkçe', flagName: 'turkey.svg' },
    { iso: 'vi', name: 'Tiếng Việt', flagName: 'vietnam.svg' },
  ]
  const websiteLanguageFlag = languages.find(lang => lang.iso === websiteLanguage)?.flagName ?? 'uk.svg';

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const showDropdown = () => { setIsDropdownOpen(true); };

  const hideDropdown = () => { setIsDropdownOpen(false); };

  const handleWebsiteLanguageSelection = (selectedLanguage) => {
    setWebsiteLanguage(selectedLanguage.iso); // Update to use the selected language's name
    setIsDropdownOpen(false); // Close the dropdown after selection
  }

  const app = initFirebase();
  const auth = getAuth(app);
  const signOut = () => {
    auth.signOut();
  };

  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const createCheckoutSession = async () => {
    setIsLoadingStripe(true)

    const stripe = await stripePromise;
    const user = auth.currentUser;

    if (user) {
      const uid = user.uid;
      const response = await fetch('https://j9pipkwwql.execute-api.eu-west-2.amazonaws.com/default/CreateStripeCheckoutSession', {
        method: 'POST',
        body: JSON.stringify({ uid: uid }),
      });
      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } else {
      console.log("no user")
      // Handle the case where there is no user logged in
    }
  };


  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 768;



  return (
    <div className="absolute flex flex-row justify-between w-full">
      <span className='flex flex-row m-4 items-center'>
        {user?.setOnboardingSubscription ? (
          <Link to="/" className="no-underline">
            <h1 className='font-madimi text-3xl'>bentolingo</h1>
          </Link>
        ) : (
          <div className='flex flex-row items-center'>
            <h1 className='font-madimi text-3xl'>bentolingo</h1>
            {/* <div className='font-opensans ml-4 underline'>Learning a different language?</div> */}
          </div>
        )}

        {signedIn && user?.setOnboardingSubscription && (
          <>
            {!isMobile && (
              <>

                {userLanguageFlag == 'uk.svg' ? (
                  <>
                    <div className='relative mx-4' style={{ height: '2rem', width: '2rem' }}>
                      <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                      <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                    </div>
                  </>
                ) : (
                  <img className="w-8 mx-4" src={`${process.env.PUBLIC_URL}/flags/${userLanguageFlag}`} />
                )}

                <img
                  className="w-6"
                  src={`${process.env.PUBLIC_URL}/${userLevelIcon}`}
                  alt={`${user.languageLevel} level`}
                />
              </>
            )}
            <UTCCountdown serverDateTime={serverDateTime} />
          </>
        )}
      </span>


      <div className='flex flex-row items-center'>
        {!signedIn ? (
          <>
            {websiteLanguageFlag == 'uk.svg' ? (
              <>
                <div className='relative' style={{ height: '2rem', width: '2rem' }}>
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                </div>
              </>
            ) : (
              <img className="w-8" src={`${process.env.PUBLIC_URL}/flags/${websiteLanguageFlag}`} alt='flag' />
            )}



            <div className="relative group mr-2">
              <button onClick={toggleDropdown} onMouseEnter={showDropdown} className="flex items-center pl-4 py-2 text-sm text-gray-700 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent">
                <svg
                  className={`w-5 h-5 transform transition-transform duration-150 ease-in-out ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isDropdownOpen && (
                <div onMouseLeave={hideDropdown} className="absolute hidden group-hover:block z-50 w-52 py-1 mt-0 bg-white border border-gray-200 rounded-md shadow-lg right-0">
                  {languages.map((language) => (
                    <div onClick={() => handleWebsiteLanguageSelection(language)} className='flex flex-row items-center font-opensans ml-2 hover:scale-105 cursor-pointer'>

                      {language.flagName == 'uk.svg' ? (
                        <>
                          <div className='relative mr-4' style={{ height: '2rem', width: '2rem' }}>
                            <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                            <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                          </div>
                        </>
                      ) : (
                        <img className="w-8 mr-4" src={`${process.env.PUBLIC_URL}/flags/${language.flagName}`} />
                      )}

                      {language.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* {websiteLanguage} */}

            <Link to="/signin">
              <button className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans font-bold' : 'font-madimi'} w-max text-white px-3 py-1 text-lg rounded-md hover:scale-105 bg-navy m-4`}>{translations[websiteLanguage].log_in}</button>
            </Link>
          </>
        ) : (
          <>
            {signedIn && !user.isPremium && user?.setOnboardingSubscription && (
              isLoadingStripe ? (
                <div className="spinnerSmall"></div>
              ) : (
                <Link to='/subscribe' className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} hidden md:block w-max px-4 py-2 text-xl z-10 rounded-md hover:scale-105 animated-gradient-bg text-white`}>
                  {translations[user.websiteLanguage].get_premium}
                </Link>
              )
            )}
            <div className="relative group mr-4">
              <button onClick={toggleDropdown} onMouseEnter={showDropdown} className="flex items-center pl-4 py-2 text-sm text-gray-700 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent">
                <svg
                  className={`w-5 h-5 transform transition-transform duration-150 ease-in-out ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {isDropdownOpen && (
                <div onMouseLeave={hideDropdown} className="absolute hidden group-hover:block z-50 w-52 py-1 mt-0 bg-white border border-gray-200 rounded-md shadow-lg right-0">
                  {user.setOnboardingSubscription && (
                    <>
                      <Link to="/settings" className="no-underline">
                        <div className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                          {translations[user.websiteLanguage].settings}
                        </div>
                      </Link>

                      {/* <div className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                        {translations[user.websiteLanguage].show_tutorial}
                      </div> */}
                      <Link to="/faq" className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                        {translations[user.websiteLanguage].faq}
                      </Link>
                      {/* <Link to="/termsofservice" className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                        {translations[user.websiteLanguage].terms_of_service}
                      </Link> */}
                      {/* <Link to="/privacypolicy" className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                        {translations[user.websiteLanguage].privacy_policy}
                      </Link> */}
                      {/* <div className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                        {translations[user.websiteLanguage].dark_mode}
                      </div> */}

                      <div className='w-full h-[1px] bg-[#ccc]'></div>
                    </>
                  )}
                  <div onClick={signOut} className='flex flex-row items-center font-opensans mx-4 my-2 hover:scale-105 cursor-pointer'>
                    {translations[user.websiteLanguage ? user.websiteLanguage : localStorage.getItem('websiteLanguage')].sign_out}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
