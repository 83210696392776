import './App.css';
import React, { useState, useEffect } from 'react';
import { auth } from './utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import AuthenticatedAppComponent from './App/AuthenticatedAppComponent';
import UnauthenticatedAppComponent from './App/UnauthenticatedAppComponent';


function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {

    if (!localStorage.getItem('websiteLanguage')) {
      localStorage.setItem('websiteLanguage', 'en');
    }
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth.currentUser]);


  // if (true) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen w-full bg-backgroundBlue">
  //       <h1 className='font-madimi text-3xl'>bentolingo</h1>
  //     </div>
  //   );
  // }

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen w-full">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Router>
      {user ? <AuthenticatedAppComponent user={user} /> : <UnauthenticatedAppComponent setUser={setUser} />}
    </Router>
  );
}

export default App;

