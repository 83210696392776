import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';

function PrivacyPolicy() {

  return (

    <div className="flex flex-col items-center font-opensans mt-24 w-screen">


      <div className='flex flex-row items-center w-[896px] max-w-[90%] mb-4'>
        <h2 className='font-madimi text-2xl'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>Privacy Policy</span></h2>
      </div>

      <div className="flex flex-col border-white border-2 text-md bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        <div className='text-xl font-bold'>Last updated: 16th April 2024</div>

        <div className='font-bold text-xl mt-4'>Cookies</div>

        <p>We comply with GDPR and only use "<a href="https://ico.org.uk/for-organisations/guide-to-pecr/guidance-on-the-use-of-cookies-and-similar-technologies/what-are-the-rules-on-cookies-and-similar-technologies/" target="_blank">strictly necessary</a>"
          third-party cookies, when required to provide the service that you request:</p>

        <ul class="list-disc pl-6">
          <li><b>Google Firebase Authentication</b> &mdash; required to authenticate users</li>
          <li><b>Stripe</b> &mdash; our payment processor, required when you purchase a subscription</li>
          <li><b>Google Analytics</b> &mdash; to report website usage statistics without personally identifying individual visitors</li>
        </ul>


        <div className='font-bold text-xl mt-4'>Your Personal Data</div>
        <p>Personal data is only stored once you create a bentolingo account, which happens when you Sign In With Google for the first time.</p>
        <p className='mt-4'>bentolingo stores the following personal information about you:</p>
        <ul class="list-disc pl-6">
          <li>Email</li>
        </ul>

        <p className='mt-4'>You can delete your account from the Settings page.</p>

      </div>




    </div>
  );
}

export default PrivacyPolicy;
