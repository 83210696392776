import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from "react-router-dom";
import translations from '../Localisation/ChooseWebLanguage.json';

import './../Home/Home.css';
import './../App.css';

const languages = [
  { iso: 'en', name: 'English', flagName: 'uk.svg' },
  { iso: 'zh', name: '普通话', flagName: 'china.svg' },
  { iso: 'es', name: 'Español', flagName: 'spain.svg' },
  { iso: 'hi', name: 'हिन्दी', flagName: 'india.svg' },
  { iso: 'ar', name: 'العربية', flagName: 'arabic.svg' },
  { iso: 'pt', name: 'Português', flagName: 'portugal.svg' },
  { iso: 'ru', name: 'Русский', flagName: 'russia.svg' },
  { iso: 'ja', name: '日本語', flagName: 'japan.svg' },
  { iso: 'fr', name: 'Français', flagName: 'france.svg' },
  { iso: 'de', name: 'Deutsch', flagName: 'germany.svg' },
  { iso: 'ko', name: '한국어', flagName: 'korea.svg' },
  { iso: 'tr', name: 'Türkçe', flagName: 'turkey.svg' },
  { iso: 'vi', name: 'Tiếng Việt', flagName: 'vietnam.svg' },
]



function ChooseWebLanguage({ user, setUser }) {
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);

  const handleLanguageSelect = async (language) => {
    setShowLoading(true);

    try {
      const response = await manageUser(user.uid, language);
      console.log(response);
      if (response) {
        setUser(response);
  
        // Check each property and navigate accordingly
        if (!response.learningLanguage) {
          navigate("/chooselanguage");
        } else if (!response.websiteLanguage) {
          navigate("/userlanguage");
        } else if (!response.languageLevel) {
          navigate("/chooselevel");
        } else if (!response.setOnboardingSubscription) {
          navigate("/subscribe");
        } else {
          navigate("/");
        }
      } else {
        // If response or response.data is not as expected, handle the case
        console.error('Unexpected response structure:', response);
        navigate("/error");
      }
    } catch (error) {
      // Handle error from manageUser, perhaps show an error message to the user
      console.error('Error managing user:', error);
      navigate("/error");
    }
  };

  const manageUser = async (uid, isoCode) => {
    console.log(uid);

    try {
      const response = await axios({
        method: 'put',
        url: 'https://qygqv9ukob.execute-api.eu-west-2.amazonaws.com/ManageUser',
        data: {
          uid: uid,
          websiteLanguage: isoCode
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log(response);
      return response.data; // This will return the data from the response

    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be handled where the function is called
      navigate("/error");
    }
  };


  return (
    <div className='flex mx-6 min-h-[100dvh] items-center justify-center'>
      {showLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className='flex flex-col justify-center'>
          <h2 className='font-opensans text-2xl md:text-3xl font-bold mt-20 md:mt-0 mb-4 md:mb-8 text-center'>{translations[user.websiteLanguage ? user.websiteLanguage : localStorage.getItem('websiteLanguage')].language_preference}</h2>
          <div className='flex flex-row flex-wrap items-center justify-center w-[896px] max-w-[90vw]'>
            {languages.map((country) => (
              <button key={country.name} onClick={() => handleLanguageSelect(country.iso)}>
                <div className='flex flex-col items-center justify-center border-white border-2 bg-white bg-opacity-50 text-navy hover:text-white m-4 w-32 h-32 rounded-3xl hover:cursor-pointer hover:animated-gradient-bg'>
                  
                  {country.iso == 'en' ? (
                    <>
                      <div className='relative w-[4rem] h-[4rem] mx-6 mb-2'>
                        <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                        <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                      </div>
                    </>
                  ) : (
                    <img className="w-16 mx-6 mb-2" src={`${process.env.PUBLIC_URL}/flags/${country.flagName}`} alt={`${country.name} flag`} />
                  )}


                  <p className='font-opensans text-lg font-bold'>{country.name}</p>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

    </div>
  );
}

export default ChooseWebLanguage;
