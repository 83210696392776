import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import './../App.css';
import './../Home/MobileHome.css';
// import './../Home/Home.css';
import translations from '../Localisation/Today.json';


function Today({ user, serverDateTime }) {

  const navigate = useNavigate();
  const location = useLocation();

  const [serverDate, setServerDate] = useState('');
  const [date, setDate] = useState(''); // Define the state for 'date'
  const [isLoading, setIsLoading] = useState(true);
  const [userTasks, setUserTasks] = useState([false, false, false, false, false, false]);
  const [vocabResults, setVocabResults] = useState([]);

  const languageCodes = {
    english: "en",
    french: "fr",
    spanish: "es",
    german: "de",
    russian: "ru"
  };



  useEffect(() => {
    const fetchData = async () => {
      const todayString = new Date(serverDateTime).toISOString().split('T')[0];
      setServerDate(todayString);
      setDate(todayString);

      const searchParams = new URLSearchParams(location.search);
      let urlDate;
      for (const [key, value] of searchParams.entries()) {
        if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
          urlDate = key;
          break;
        }
      }
      if (new Date(urlDate) > new Date(todayString) || new Date(urlDate) < new Date(2024, 3, 22)) {
        navigate("/")
      } else {
        try {

          const chosenDate = urlDate || todayString;
          const chosenDateParts = chosenDate.split('-');
          const chosenDateShort = chosenDateParts[1] + '-' + chosenDateParts[2]

          if (chosenDate) {
            await Promise.all([
              checkDateAndFetchTasks(chosenDate),
              fetchPrompts(chosenDateShort)
            ]);
          }

        } catch {
          navigate("/error");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData()
  }, []);


  const navigateToDate = (newDateString) => {
    if (!newDateString) return;
    const newDate = new Date(newDateString);
    const formattedDate = newDate.toISOString().split('T')[0]; // Format the date to YYYY-MM-DD
    if (formattedDate === serverDate) {
      navigate('/');
    } else {
      navigate(`/?${formattedDate}`);
    }
  };

  const checkDateAndFetchTasks = async (currentDate) => {
    const currentUrl = window.location.href;
    const datePattern = /(?:\?)(\d{4}-\d{2}-\d{2})$/;
    const match = currentUrl.match(datePattern);
    let dateToUse = currentDate;
    if (match && match[1]) {
      const extractedDate = new Date(match[1]);
      if (!isNaN(extractedDate.getTime()) && extractedDate <= new Date(currentDate)) {
        dateToUse = match[1];
      } else {
        navigate('/');
        setDate(dateToUse);  // Update the state with the correct date
        await fetchUserTask(user.uid, dateToUse);
      }
    }
    setDate(dateToUse);  // Update the state with the correct date
    await fetchUserTask(user.uid, dateToUse);
  };

  const fetchUserTask = async (uid, dateToFetch) => {
    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid, date: dateToFetch });
      const userTask = response.data;
      setUserTasks([
        !!userTask.vocabulary,
        !!userTask.listening,
        !!userTask.reading,
        !!userTask.speaking,
        !!userTask.writing,
        !!userTask.culture
      ]);
      if (userTask.vocabulary) {
        setVocabResults(userTask.vocabulary.split(','));
      }
    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    }
  };

  function formatDate(dateString) {

    const date = new Date(dateString);

    const lang = translations[user.websiteLanguage];
    const month = lang.monthNames[date.getMonth()];
    const day = lang.dayNames[date.getDay()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();

    switch (user.websiteLanguage) {
      case "en":
      case "es":
      case "pt":
      case "fr":
      case "de":
        return `${day}, ${dayOfMonth} ${month} ${year}`;
      case "zh":
      case "ja":
      case "ko":
        return `${year}年${month}${dayOfMonth}日 ${day}`;
      case "hi":
        return `${day}, ${dayOfMonth} ${month}, ${year}`;
      case "ar":
        return `${day}, ${dayOfMonth} ${month} ${year}`;
      case "ru":
        return `${day}, ${dayOfMonth} ${month} ${year} г.`;
      case "tr":
        return `${day}, ${dayOfMonth} ${month} ${year}`;
      case "vi":
        return `${dayOfMonth} ${month} năm ${year}`;
      default:
        return `${day}, ${dayOfMonth} ${month} ${year}`;
    }
  }


  const isInPast = (someDateString) => {
    if (!serverDate) return false; // Guard against invalid serverDate
    const someDate = new Date(someDateString);
    someDate.setUTCHours(0, 0, 0, 0);
    const todayUTC = new Date(serverDate);
    todayUTC.setUTCHours(0, 0, 0, 0);
    return someDate < todayUTC;
  };

  const isPastOrPresent = (someDateString) => {
    const someDate = new Date(someDateString);
    const todayUTC = new Date(serverDate);
    return someDate <= todayUTC; // <= allows for current date
  };

  const goToPreviousDate = () => {
    if (!date || !serverDate) return; // Guard against incomplete state
    const currentDate = new Date(date);
    currentDate.setUTCDate(currentDate.getUTCDate() - 1);
    const newDateISO = currentDate.toISOString().split('T')[0];
    if (isPastOrPresent(newDateISO)) {
      setDate(newDateISO);
      navigateToDate(newDateISO);
    }
  };

  const goToNextDate = () => {
    if (!date || !serverDate) return; // Guard against incomplete state
    const currentDate = new Date(date);
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    const newDateISO = currentDate.toISOString().split('T')[0];
    if (isPastOrPresent(newDateISO)) {
      setDate(newDateISO);
      navigateToDate(newDateISO);
    }
  };



  //Get Prompts

  const [listeningPrompt, setListeningPrompt] = useState('');
  const [listeningTargetPrompt, setListeningTargetPrompt] = useState('');

  const [readingNativePrompt, setReadingNativePrompt] = useState('');
  const [readingTargetPrompt, setReadingTargetPrompt] = useState('');

  const [speakingNativePrompt, setSpeakingNativePrompt] = useState('');
  const [speakingTargetPrompt, setSpeakingTargetPrompt] = useState('');

  const [writingNativePrompt, setWritingNativePrompt] = useState('');
  const [writingTargetPrompt, setWritingTargetPrompt] = useState('');

  const [cultureNativePrompt, setCultureNativePrompt] = useState('');
  const [cultureTargetPrompt, setCultureTargetPrompt] = useState('');

  const fetchPrompts = async (dateString) => {
    await Promise.all([
      fetchListeningPrompt(dateString),
      getReadingPrompt(dateString),
      getSpeakingPrompt(dateString),
      getWritingPrompt(dateString),
      getCulturePrompt(dateString)
    ]);
  };

  const fetchListeningPrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://oymtw4esd0.execute-api.eu-west-2.amazonaws.com/GetListeningPrompt',
        JSON.stringify({
          date: dateToFind,
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setListeningPrompt(answerResponse.data[user.websiteLanguage])
      setListeningTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (err) {
      console.error('Failed to fetch prompt:', err.message);
      setListeningPrompt("Unable to fetch prompt");
    }
  };

  const getReadingPrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://fi89g04k25.execute-api.eu-west-2.amazonaws.com/GetReadingPrompt',
        JSON.stringify({
          date: dateToFind,
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setReadingNativePrompt(answerResponse.data[user.websiteLanguage])
      setReadingTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setReadingNativePrompt("Unable to fetch prompt")
      setReadingTargetPrompt("Unable to fetch prompt")
    }
  };

  const getSpeakingPrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://32990hq10c.execute-api.eu-west-2.amazonaws.com/GetTodaysSpeakingPrompt',
        JSON.stringify({
          date: dateToFind,
          level: user.languageLevel.toLowerCase(),
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setSpeakingNativePrompt(answerResponse.data[user.websiteLanguage])
      setSpeakingTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setSpeakingNativePrompt("Unable to fetch prompt")
      setSpeakingTargetPrompt("Unable to fetch prompt")
    }
  };

  const getWritingPrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://7qabb5800h.execute-api.eu-west-2.amazonaws.com/GetWritingPrompt',
        JSON.stringify({
          date: dateToFind,
          level: user.languageLevel.toLowerCase(),
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setWritingNativePrompt(answerResponse.data[user.websiteLanguage])
      setWritingTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setWritingNativePrompt("Unable to fetch prompt")
      setWritingTargetPrompt("Unable to fetch prompt")
    }
  };


  const getCulturePrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://u3d0c8s1mi.execute-api.eu-west-2.amazonaws.com/GetPrompt',
        JSON.stringify({
          task: 'Culture',
          date: dateToFind,
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setCultureNativePrompt(answerResponse.data[user.websiteLanguage])
      setCultureTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setCultureNativePrompt("Unable to fetch prompt")
      setCultureTargetPrompt("Unable to fetch prompt")
    }
  };




  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 768;







  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-col h-screen w-screen">
        <img className="w-16 mx-8 animate-scale-in-out drop-shadow-[0_0px_4px_rgba(100,100,100,0.3)]" src={`${process.env.PUBLIC_URL}/bento.png`} alt='bento box' />
      </div>
    )
  }

  return (
    <div className="contentContainer" key={date}>

      <div className='flex flex-row items-center'>
        <div className={`flex items-center justify-center font-opensans text-xl font-bold mb-4 text-white bg-navy mx-4 w-8 h-8 rounded-full ${new Date(date) <= new Date(2024, 3, 23) ? 'opacity-30' : 'hover:cursor-pointer hover:scale-105'}`}
          onClick={new Date(date) <= new Date(2024, 3, 23) ? undefined : goToPreviousDate} disabled={!isPastOrPresent(serverDate) || new Date(date) <= new Date(2024, 3, 23)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="14 18 8 12 14 6"></polyline>
          </svg>

        </div>
        <div className='font-opensans text-xl font-bold mb-4 text-navy'>{formatDate(date)}</div>
        <div
          className={`flex items-center justify-center font-opensans text-xl font-bold mb-4 text-white bg-navy mx-4 w-8 h-8 rounded-full ${date === serverDate ? 'opacity-30' : 'hover:cursor-pointer hover:scale-105'}`}
          onClick={date !== serverDate ? goToNextDate : undefined}
          disabled={date === serverDate}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="10 18 16 12 10 6"></polyline>
          </svg>
        </div>

      </div>


      {isMobile ? (
        <>
          <div className="mobileBentoBox">

            <div className="mobileBentoBoxRow">
              {/* VOCABULARY */}
              {(isInPast(date) && !userTasks[0]) ? (
                <div className={`relative opacity-50 linkReset mobileBentoElement mobileBentoElementLeft mobileBentoElementWide ${userTasks[0] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[0]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">
                    {userTasks[0]
                      ? translations[user.learningLanguage].words_correct.replace("{X}", vocabResults.filter(task => task === 'correct').length)
                      : translations[user.learningLanguage].words_to_review}
                  </p>
                </div>
              ) : (
                <Link to={
                  isInPast(date)
                    ? `/vocabulary?date=${date}`
                    : '/vocabulary'
                } className={`relative linkReset mobileBentoElement mobileBentoElementLeft mobileBentoElementWide ${userTasks[0] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[0]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">
                    {userTasks[0]
                      ? translations[user.learningLanguage].words_correct.replace("{X}", vocabResults.filter(task => task === 'correct').length)
                      : translations[user.learningLanguage].words_to_review}
                  </p>
                </Link>
              )}

              {/* LISTENING */}
              {(isInPast(date) && !userTasks[1]) ? (
                <div className={`relative opacity-50 linkReset mobileBentoElement mobileBentoElementRight mobileBentoElementStandard ${userTasks[1] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[1]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{listeningTargetPrompt}</p>
                </div>
              ) : (
                <Link to={
                  isInPast(date)
                    ? `/listening?date=${date}`
                    : '/listening'
                } className={`relative linkReset mobileBentoElement mobileBentoElementRight mobileBentoElementStandard ${userTasks[1] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[1]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{listeningTargetPrompt}</p>
                </Link>
              )}
            </div>

            <div className="mobileBentoBoxRow">
              {/* READING */}
              {(isInPast(date) && !userTasks[2]) ? (
                <div className={`relative opacity-50 linkReset mobileBentoElement mobileBentoElementLeft mobileBentoElementStandard ${userTasks[2] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[2]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{readingTargetPrompt}</p>
                </div>
              ) : (
                <Link to={
                  isInPast(date)
                    ? `/reading?date=${date}`
                    : '/reading'
                } className={`relative linkReset mobileBentoElement mobileBentoElementLeft mobileBentoElementStandard ${userTasks[2] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[2]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{readingTargetPrompt}</p>
                </Link>
              )}

              {/* SPEAKING */}
              {user.isPremium && (isInPast(date) && !userTasks[3]) ? (
                <div className={`relative opacity-50 linkReset mobileBentoElement mobileBentoElementRight mobileBentoElementWide ${userTasks[3] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[3]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{speakingTargetPrompt}</p>
                </div>
              ) : (
                <Link to={user.isPremium ? (isInPast(date) ? `/speaking?date=${date}` : '/speaking') : '/subscribe'} className={`relative linkReset mobileBentoElement mobileBentoElementRight mobileBentoElementWide ${userTasks[3] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[3]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{speakingTargetPrompt}</p>
                  {!user.isPremium && (
                    <>
                      <div className='absolute top-0 w-full h-full bg-white opacity-50'></div>
                      <img
                        className="absolute w-12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        src={`${process.env.PUBLIC_URL}/lock.svg`}
                      />
                    </>
                  )}
                </Link>
              )}
            </div>

            <div className="mobileBentoBoxRow">
              {/* WRITING */}
              {user.isPremium && (isInPast(date) && !userTasks[4]) ? (
                <div className={`relative opacity-50 linkReset mobileBentoElement mobileBentoElementLeft mobileBentoElementWide ${userTasks[4] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[4]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{writingTargetPrompt}</p>
                </div>
              ) : (
                <Link to={user.isPremium ? (isInPast(date) ? `/writing?date=${date}` : '/writing') : '/subscribe'} className={`relative linkReset mobileBentoElement mobileBentoElementLeft mobileBentoElementWide ${userTasks[4] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[4]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{writingTargetPrompt}</p>
                  {!user.isPremium && (
                    <>
                      <div className='absolute top-0 w-full h-full bg-white opacity-50'></div>
                      <img
                        className="absolute w-12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        src={`${process.env.PUBLIC_URL}/lock.svg`}
                      />
                    </>
                  )}
                </Link>
              )}

              {/* CULTURE */}
              {user.isPremium && (isInPast(date) && !userTasks[5]) ? (
                <div className={`relative opacity-50 linkReset mobileBentoElement mobileBentoElementRight mobileBentoElementStandard ${userTasks[5] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[5]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{cultureTargetPrompt}</p>
                </div>
              ) : (
                <Link to={user.isPremium ? (isInPast(date) ? `/culture?date=${date}` : '/culture') : '/subscribe'} className={`relative linkReset mobileBentoElement mobileBentoElementRight mobileBentoElementStandard ${userTasks[5] ? 'mobileBentoYellow' : 'mobileBentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'mobileBentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'mobileBentoElementHeaderBold' : 'mobileBentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[5]}</p>
                  <p className="mobileBentoElementDetail line-clamp-3 overflow-hidden">{cultureTargetPrompt}</p>
                  {!user.isPremium && (
                    <>
                      <div className='absolute top-0 w-full h-full bg-white opacity-50'></div>
                      <img
                        className="absolute w-12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        src={`${process.env.PUBLIC_URL}/lock.svg`}
                      />
                    </>
                  )}
                </Link>
              )}
            </div>


          </div>
          <div className='mobileBentoBoxDepth -mb-16'></div>
        </>
      ) : (
        <>
          <div className="bentoBox">
            <div className="bentoBoxRow">

              {/* VOCABULARY */}
              {(isInPast(date) && !userTasks[0]) ? (
                <div className={`relative opacity-50 linkReset bentoElement bentoElement2 bentoElementWide ${userTasks[0] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[0]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">
                    {userTasks[0]
                      ? translations[user.learningLanguage].words_correct.replace("{X}", vocabResults.filter(task => task === 'correct').length)
                      : translations[user.learningLanguage].words_to_review}
                  </p>
                </div>
              ) : (
                <Link to={
                  isInPast(date)
                    ? `/vocabulary?date=${date}`
                    : '/vocabulary'
                } className={`relative linkReset bentoElement bentoElement2 bentoElementWide ${userTasks[0] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[0]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">
                    {userTasks[0]
                      ? translations[user.learningLanguage].words_correct.replace("{X}", vocabResults.filter(task => task === 'correct').length)
                      : translations[user.learningLanguage].words_to_review}
                  </p>
                </Link>
              )}

              {/* LISTENING */}
              {(isInPast(date) && !userTasks[1]) ? (
                <div className={`relative opacity-50 linkReset bentoElement bentoElement2 bentoElementStandard ${userTasks[1] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[1]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{listeningTargetPrompt}</p>
                </div>
              ) : (
                <Link to={
                  isInPast(date)
                    ? `/listening?date=${date}`
                    : '/listening'
                } className={`relative linkReset bentoElement bentoElement2 bentoElementStandard ${userTasks[1] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[1]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{listeningTargetPrompt}</p>
                </Link>
              )}

              {/* READING */}
              {(isInPast(date) && !userTasks[2]) ? (
                <div className={`relative opacity-50 linkReset bentoElement bentoElement2 bentoElementStandard ${userTasks[2] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[2]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{readingTargetPrompt}</p>
                </div>
              ) : (
                <Link to={
                  isInPast(date)
                    ? `/reading?date=${date}`
                    : '/reading'
                } className={`relative linkReset bentoElement bentoElement2 bentoElementStandard ${userTasks[2] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[2]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{readingTargetPrompt}</p>
                </Link>
              )}

            </div >
            <div className="bentoBoxRow">

              {/* SPEAKING */}
              {user.isPremium && (isInPast(date) && !userTasks[3]) ? (
                <div className={`relative opacity-50 linkReset bentoElement bentoElement2 bentoElementStandard ${userTasks[3] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[3]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{speakingTargetPrompt}</p>
                </div>
              ) : (
                <Link to={user.isPremium ? (isInPast(date) ? `/speaking?date=${date}` : '/speaking') : '/subscribe'} className={`relative linkReset bentoElement bentoElement5 bentoElementStandard ${userTasks[3] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[3]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{speakingTargetPrompt}</p>
                  {!user.isPremium && (
                    <>
                      <div className='absolute top-0 w-full h-full bg-white opacity-50'></div>
                      <img
                        className="absolute w-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        src={`${process.env.PUBLIC_URL}/lock.svg`}
                        alt="flag"
                      />
                    </>
                  )}
                </Link>
              )}

              {/* WRITING */}
              {user.isPremium && (isInPast(date) && !userTasks[4]) ? (
                <div className={`relative opacity-50 linkReset bentoElement bentoElement2 bentoElementWide ${userTasks[4] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[4]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{writingTargetPrompt}</p>
                </div>
              ) : (
                <Link to={user.isPremium ? (isInPast(date) ? `/writing?date=${date}` : '/writing') : '/subscribe'} className={`relative linkReset bentoElement bentoElement5 bentoElementWide ${userTasks[4] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[4]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{writingTargetPrompt}</p>
                  {!user.isPremium && (
                    <>
                      <div className='absolute top-0 w-full h-full bg-white opacity-50'></div>
                      <img
                        className="absolute w-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        src={`${process.env.PUBLIC_URL}/lock.svg`}
                        alt="flag"
                      />
                    </>
                  )}
                </Link>
              )}

              {/* CULTURE */}
              {user.isPremium && (isInPast(date) && !userTasks[5]) ? (
                <div className={`relative opacity-50 linkReset bentoElement bentoElement2 bentoElementStandard ${userTasks[5] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[5]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{cultureTargetPrompt}</p>
                </div>
              ) : (
                <Link to={user.isPremium ? (isInPast(date) ? `/culture?date=${date}` : '/culture') : '/subscribe'} className={`relative linkReset bentoElement bentoElement5 bentoElementStandard ${userTasks[5] ? 'bentoYellow' : 'bentoNavy'}`}>
                  <p className={`${user.websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[user.websiteLanguage].taskNames[5]}</p>
                  <p className="bentoElementDetail line-clamp-4 overflow-hidden">{cultureTargetPrompt}</p>
                  {!user.isPremium && (
                    <>
                      <div className='absolute top-0 w-full h-full bg-white opacity-50'></div>
                      <img
                        className="absolute w-16 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        src={`${process.env.PUBLIC_URL}/lock.svg`}
                        alt="flag"
                      />
                    </>
                  )}
                </Link>
              )}





            </div>
          </div >

          <div className='bentoBoxDepth'></div>
        </>
      )
      }

    </div >
  );
}

export default Today;
