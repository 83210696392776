import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import './../App.css';
import { initFirebase } from '../utils/firebase';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import translations from '../Localisation/SignIn.json';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OtacgJYVWdaEBPsh6I9pu3mWyYenx2YqG4WgeZI03e4g9yoE8UfvNKbiqEgywHUKgBD0e2O34OhEA3vacAIiDf500OeFmYRlw');

function SignIn({ setUser, websiteLanguage }) {
  const navigate = useNavigate();

  const [userName, setUserName] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  const app = initFirebase();
  const auth = getAuth(app);
  const user = auth.currentUser;
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // setUser(currentUser); // Set user in state
      if (currentUser) {
        console.log(currentUser.uid)
      } else {
        // User is signed out
        console.log("No user signed in");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);


  const signIn = async () => {
    const result = await signInWithPopup(auth, provider);
    // No need to manually set userName here, onAuthStateChanged will handle it

    console.log(result)

    if (result.user) {

      console.log(result.user.uid)
      // setUid(result.user.uid)
      // setWebsiteLanguage(localStorage.getItem('websiteLanguage'))

      try {
        const user = await manageUser(result.user.uid);
        console.log(user);
        setUser(user)
    
        if (user.learningLanguage == null) {
          navigate("/chooselanguage");
        } else if (user.languageLevel == null) {
          navigate("/chooselevel");
        } else {
          navigate("/");
        }
      } catch (error) {
        // Handle error from manageUser, perhaps show an error message to the user
        console.error('Error managing user:', error);
        navigate("/error");
      }

      // User
      // uid

      // language learning
      // language level

      // website language

      // expires at
      // subscroption id

    }

    // nav to Today if onboarding complete...

  };

  const signOut = () => {
    auth.signOut();
  };

  // const [uid, setUid] = useState('');
  // const [websiteLanguage, setWebsiteLanguage] = useState('');


  const manageUser = async (uid) => {
    console.log(uid);
  
    try {
      const response = await axios({
        method: 'put',
        url: 'https://qygqv9ukob.execute-api.eu-west-2.amazonaws.com/ManageUser',
        data: {
          uid: uid,
          agreedToTerms: new Date().toISOString()
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      console.log(response);
      return response.data; // This will return the data from the response
  
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be handled where the function is called
      navigate("/error");
    }
  };
  


  return (
    <div className="contentContainer">

      {/* <button onClick={manageUser} className='bg-navy text-white p-4'>api</button> */}

      <h2 className='font-madimi text-2xl mb-4'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[websiteLanguage].welcome}</span></h2>
      <div className="flex flex-col items-center border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[420px] max-w-[90%]">
        <div className='font-madimi text-3xl mb-4'>bentolingo</div>
        <div className='font-opensans text-navy'>{translations[websiteLanguage].continue_sign_in}</div>
        <img onClick={signIn} className="w-max my-8 cursor-pointer" src={`${process.env.PUBLIC_URL}/signInWithGoogle.svg`} alt='Sign in with Google' />
        <div className='font-opensans text-navy opacity-80'>By signing in with Google, you agree to <span className='font-madimi text-normal'>bentolingo</span>'s <Link to="/privacypolicy" className='underline'>Privacy Policy</Link> and <Link Link to="/termsofservice" className='underline'>Terms of Service</Link>.</div>    
      </div>

      {user && (
        <>
          <p>{user.uid}</p>
          <button onClick={signOut} className="font-madimi my-4 px-4 py-2 bg-navy text-white rounded-md">Sign out</button>
        </>
      )}

    </div >
  );
}

export default SignIn;
