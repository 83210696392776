import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';

function FAQ() {

  return (

    <div className="flex flex-col items-center font-opensans mt-24">


      <div className='flex flex-row items-center w-[896px] max-w-[90vw] mb-4'>
        <h2 className='font-madimi text-2xl'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>FAQ</span></h2>
      </div>

      <div className="flex flex-col border-white border-2 text-md bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
        <div className='text-xl font-bold mb-1'>What's with the countdown timer?</div>
        <div className='text-md'>The countdown timer at the top shows the hours and minutes left in today's session. This is counting down to 00:00 UTC. You can't spoof your time and complete tasks for days in the past or future, as we could very easily incur fees from OpenAI's APIs that exceed your monthly subscription fee (it wouldn't be sustainable for us).</div>

        <div className='text-xl font-bold mt-6 mb-1'>Why don't you support _ language?</div>
        <div className='text-md'>If your target, or native language, is not supported, <a className='underline' href="mailto:bentolingoai@gmail.com">send us an email</a> to let us know. We have plans to add support for French, German, Russian, and Japanese, what comes next will depend on what is most requested.</div>

        <div className='text-xl font-bold mt-6 mb-1'>British or American English?</div>
        <div className='text-md mb-1'><span className='font-madimi'>bentolingo</span> is created in the UK. For that reason, we've done our best to prompt the AI for responses and content in British English. However, GPT AI can sometimes get somewhat confused, and may switch to American English.</div>
        <div className='text-md'>Oh, and... about the flag 🇬🇧. We know the flag of England 🏴󠁧󠁢󠁥󠁮󠁧󠁿 may more accurately represent the English language, rather then the flag of the United Kingdom, which is home to other languages too, such as Welsh and Gaelic. The simple reality is that 🇬🇧 is more globally recognised as representing "English" than 🏴󠁧󠁢󠁥󠁮󠁧󠁿.</div>

        <div className='text-xl font-bold mt-6 mb-1'>Mobile app?</div>
        <div className='text-md'>A mobile app for iOS and Android is coming soon! Until then, you can add <span className='font-madimi'>bentolingo</span> to your home screen from your mobile web browser — we've carefully optimised everything for mobile so it works just as well as it does on desktop.</div>

        <div className='text-xl font-bold mt-6 mb-1'>Who's "we"?</div>
        <div className='text-md'>We (the creators of <span className='font-madimi'>bentolingo</span>) are <span className='whitespace-nowrap'>🇬🇧 Joseph</span> and <span className='whitespace-nowrap'>🇷🇺 Anna</span>, husband & wife independent dev duo with backgrounds in language education. Joseph taught English in Vietnam for 15 months, and Anna tutored English in Russia.</div>
      </div>

      


    </div>
  );
}

export default FAQ;
