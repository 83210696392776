import '../App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { fetchCurrentDate } from '../utils/DateTime';

import AppComponentWithRoutes from './AppComponentWithRoutes';


function AuthenticatedAppComponent({ user }) {

  const navigate = useNavigate();
  const [checkingUser, setCheckingUser] = useState(true);
  const [serverDateTime, setServerDateTime] = useState(null);
  const [fetchedServerDateTime, setFetchedServerDateTime] = useState(false);
  const [awsUser, setAwsUser] = useState(null);

  useEffect(() => {
    setCheckingUser(true);

    const runManageUser = async () => {
      try {
        const response = await axios({
          method: 'put',
          url: 'https://qygqv9ukob.execute-api.eu-west-2.amazonaws.com/ManageUser',
          data: {
            uid: user.uid
          },
          headers: {
            'Content-Type': 'application/json',
          }
        });

        setAwsUser(response.data)

        const dateTime = await fetchCurrentDate("datetime");
        console.log(dateTime)
        setServerDateTime(dateTime.timestamp)

        if (!response.data.learningLanguage) {
          navigate("/chooselanguage");
        } else if (!response.data.websiteLanguage) {
          navigate("/userlanguage");
        } else if (!response.data.languageLevel) {
          navigate("/chooselevel");
        } else if (!response.data.setOnboardingSubscription) {
          navigate("/subscribe");
        }

      } catch (error) {
        console.error(error);
        navigate("/error")
        throw error;
      } finally {
        setFetchedServerDateTime(true)
        setTimeout(() => {
          setCheckingUser(false)
        }, 200); // to prevent weird fade-out-in
      }
    };

    runManageUser();
  }, [user]);

  if (checkingUser || !fetchedServerDateTime) {
    return (
      <div className="flex items-center justify-center flex-col h-screen w-screen">
        <img className="w-16 mx-8 animate-scale-in-out" src={`${process.env.PUBLIC_URL}/bento.png`} alt='bento box' />
      </div>
    )
  }

  return <AppComponentWithRoutes user={awsUser} setUser={setAwsUser} serverDateTime={serverDateTime} />;
}

export default AuthenticatedAppComponent;