import './Writing.css';
import './../App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import translations from '../Localisation/Writing.json';


function Writing({ user, serverDateTime }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateFromUrl = searchParams.get('date');

  const [isLoading, setIsLoading] = useState(true);
  const [isUnsubmitted, setIsUnsubmitted] = useState(true);
  const [isMarking, setIsMarking] = useState(false);

  const [targetPrompt, setTargetPrompt] = useState('');
  const [nativePrompt, setNativePrompt] = useState('');

  const [response, setResponse] = useState('');
  const [writingTask, setWritingTask] = useState('');




  // on submit:
  //    submit to trigger writing marking
  //    that function sends to SNS
  //    FE refreshes UserWritingContent until entry found 


  useEffect(() => {
    var serverDate = serverDateTime.split('T')[0]
    console.log("serverDate: " + serverDate)

    if (user && user.uid) {
      if (dateFromUrl) {
        if (isDateParamInFuture(dateFromUrl)) {
          navigate('/')
        } else {
          fetchUserTasks(user.uid, dateFromUrl);
        }
      } else {
        fetchUserTasks(user.uid, serverDate);
      }
    }
  }, []);

  const isDateParamInFuture = (someDateString) => {
    const someDate = new Date(someDateString);
    const utcSomeDate = Date.UTC(someDate.getUTCFullYear(), someDate.getUTCMonth(), someDate.getUTCDate());

    const now = new Date(serverDateTime);
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    return utcSomeDate > utcToday;
  };

  const fetchUserTasks = async (uid, date) => {
    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid, date });
      const userTasks = response.data;
      console.log("Retrieved user task:", userTasks);

      if (userTasks.writing == true) {
        setIsUnsubmitted(false)
        await fetchWritingAnswer(date)
      } else {
        console.log("Not yet attempted")
        if (dateFromUrl) {
          navigate("/")
        } else {
          await getWritingPrompts()
        }
      }

    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    } finally {
      console.log("here");
      setIsLoading(false);
    }
  };

  const fetchWritingAnswer = async (date, retries = 0) => {
    
    try {
      const answerResponse = await axios.post('https://ydbco3t694.execute-api.eu-west-2.amazonaws.com/GetWritingAnswer', JSON.stringify({ uid: user.uid, date: date }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (answerResponse.status === 200) {
        const { prompt, nativePrompt, answer, feedback } = answerResponse.data;
        setTargetPrompt(prompt);
        setNativePrompt(nativePrompt)
        setWritingTask(answer);
        setResponse(feedback);
        setIsUnsubmitted(false);
        setIsMarking(false)
      } else {
        console.log(answerResponse);
      }
    } catch (error) {

      if (error.response && error.response.status === 404) {
        setIsMarking(true)
        console.log("Still processing...");
        // Wait for 10 seconds before retrying
        if (retries < 30) { // Limit the number of retries to prevent infinite loop
          setTimeout(() => fetchWritingAnswer(date, retries + 1), 10000);
        } else {
          console.log("Max retries reached.");
        }
      } else {
        console.error("An error occurred:", error.message);
        navigate("/error");
      }

      // console.error('Error fetching data:', error);
      // if (dateFromUrl) {
      //   navigate("/")
      // }
    }
  };

  const getWritingPrompts = async () => {
    try {
      const answerResponse = await axios.post('https://7qabb5800h.execute-api.eu-west-2.amazonaws.com/GetWritingPrompt',
        JSON.stringify({
          level: user.languageLevel.toLowerCase(),
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setNativePrompt(answerResponse.data[user.websiteLanguage])
      setTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      navigate("/error");
    } finally {
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= 500) {
      setWritingTask(newValue);
    }
  };

  const handleSubmit = async () => {
    setIsMarking(true)
    const url = "https://3q5qs0s6mk.execute-api.eu-west-2.amazonaws.com/TriggerWritingMarking";
    try {
      console.log(user.uid)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          writingTask: writingTask,
          prompt: targetPrompt,
          nativePrompt: nativePrompt,
          feedbackLanguage: user.websiteLanguage,
          targetLanguage: user.learningLanguage
        }),
      });

      console.log(response);

      if (!response.ok) {
        setIsLoading(false)
        navigate("/error");
      } else {
        fetchWritingAnswer(serverDateTime.split('T')[0])
      }
      
      setIsUnsubmitted(false)

      // return data;
    } catch (error) {
      console.error("There was an error checking the user", error);
      navigate("/error");
    }
  };












  function formatTextWithLineBreaks(text) {
    // Ensure text is defined and is a string
    if (typeof text !== 'string') {
      return null; // or return <></> to render nothing in React components
    }

    // Split the text by line breaks and process each line
    return text.split('\n').map((line, index, array) => (
      <React.Fragment key={index}>
        {line.split(/(\*\*[^*]+\*\*)/g).map((part, idx) => {
          // Check if the part is a bold marker
          if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={idx}>{part.slice(2, -2)}</strong>;
          } else {
            return part;
          }
        })}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  }




  if (isMarking) {
    return (
      <div className='flex flex-col h-screen w-screen items-center justify-center'>
        <div className="spinner mb-4"></div>
        <div>{translations[user.websiteLanguage].ai_feedback}</div>
      </div>
    )
  }

  return (
    <div>

      {isLoading ? (
        <div className='flex w-screen h-screen items-center justify-center'>
          <div className="spinner"></div>
        </div>
      ) : (

        <div className="flex flex-col items-center justify-center font-opensans mt-24">

          <div className='flex flex-row items-center w-[896px] max-w-[90vw] mb-4'>
            <img
              className="w-6 mr-2"
              src={`${process.env.PUBLIC_URL}/paper_and_pencil.svg`}
              alt='an image of a pencil and paper to represent writing'
            />
            <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].writing}</span></h2>
          </div>

          <h2 className='font-opensans text-2xl w-[896px] max-w-[90vw] font-bold'>{targetPrompt}</h2>
          <h2 className='font-opensans text-xl opacity-60 w-[896px] max-w-[90vw]'>{nativePrompt}</h2>

          <div className="flex flex-col bg-white p-4 sm:p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
            {isUnsubmitted ? (
              <textarea
                className="w-full h-52 p-2 sm:p-4 border border-paleGrey rounded-md text-md sm:text-xl"
                value={writingTask}
                onChange={handleInputChange}
                maxLength={500} // Set max length to 500
                placeholder=""
                disabled={!isUnsubmitted}
              />
            ) : (
              <div>
                <div className='mb-2 opacity-60'>{translations[user.websiteLanguage].you_wrote}</div>
                <div>{writingTask}</div>
              </div>
            )}
            {isUnsubmitted && (
              <p className="text-right opacity-50 mt-2">{translations[user.websiteLanguage].character_count.replace("{writingLength}", writingTask.length)}</p>
            )}
          </div>

          {isUnsubmitted && (
            <button className="font-madimi bg-navy text-white px-4 py-2 mt-4 text-xl rounded-md hover:scale-105 hover:cursor-pointer" onClick={handleSubmit}>{translations[user.websiteLanguage].submit} &rarr;</button>
          )}

          {!isUnsubmitted && (
            <>
              <div className='flex flex-col bg-aiFeedbackBg text-white p-4 sm:p-6 mb-12 rounded-2xl m-3 w-[896px] max-w-[90vw] font-opensans'>
                <p>{formatTextWithLineBreaks(response)}</p>
                <div className='w-full h-[1px] bg-white my-10 opacity-50'></div>
                <div className='text-sm opacity-50'>{translations[user.websiteLanguage].ai_disclaimer}</div>
              </div>
            </>
          )}

        </div>
      )}

    </div>
  );
}

export default Writing;
