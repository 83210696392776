import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import './../App.css';
import { initFirebase } from '../utils/firebase';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import translations from '../Localisation/DeleteAccount.json';


import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OtacgJYVWdaEBPsh6I9pu3mWyYenx2YqG4WgeZI03e4g9yoE8UfvNKbiqEgywHUKgBD0e2O34OhEA3vacAIiDf500OeFmYRlw');

function DeleteAccount({ user }) {

  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const app = initFirebase();
  const auth = getAuth(app);
  // const user = auth.currentUser;
  // const provider = new GoogleAuthProvider();
  const signOut = () => {
    auth.signOut();
  };

  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    const fetchSubscription = async () => {
      const response = await fetch(`https://j1777qsehg.execute-api.eu-west-2.amazonaws.com/GetUserSubscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId: user.customerId }),
      });
      if (response.ok) {
        const data = await response.json();
        setSubscription(data[0]); // Assuming you want the first subscription record
      } else {
        console.error('Failed to fetch subscription');
      }
    };

    fetchSubscription();
  }, []);

  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const handleAccessStripePortal = async () => {
    setIsLoadingStripe(true)
    try {
      const response = await fetch('https://7jhvbcfjn9.execute-api.eu-west-2.amazonaws.com/CreateStripePortalSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId: user.customerId }),
      });

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error accessing Stripe portal:', error);
      navigate("/error");
      // Handle error here
    } finally {
      // setIsLoadingStripe(false)
    }
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toUTCString(); // Converts to a string with the format "Mon, 03 Jul 2006 21:44:38 GMT"
  }

  const deleteAccount = async () => {

    setIsDeleting(true)

    try {
      const response = await fetch('https://io22rwiz6e.execute-api.eu-west-2.amazonaws.com/DeleteUserAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include other headers as required, like authentication tokens
        },
        body: JSON.stringify({ uid: user.uid, customerId: user.customerId }),
      });

      console.log(response)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Account deletion successful:', result);
      // Handle post-deletion logic, like redirecting to a different page
    } catch (error) {
      console.error('Account deletion failed:', error);
      alert('An error occurred while deleting your account. Please refresh the page and try again.');
      // Handle error case, potentially retrying or displaying an error message
    } finally {
      signOut()
    }
  };

  return (
    <div className="contentContainer">

      <h2 className='font-madimi text-2xl mb-4'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>:(</span></h2>

      {subscription ? (
        <>
          {user.isPremium && !subscription.cancel_at_period_end ? (
            <div className="flex flex-col items-center border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[420px] max-w-[90vw]">
              <div className='font-madimi text-3xl mb-4'>{translations[user.websiteLanguage].active_subscription}</div>
              <div className='font-opensans text-navy mb-4'>{translations[user.websiteLanguage].you_have_active_subscription} <span className='font-bold'>{formatDate(subscription.current_period_end)} (UTC)</span>.</div>
              <div className='font-opensans text-navy'>{translations[user.websiteLanguage].cancel_subscription_first}</div>

              {isLoadingStripe ? (
                <div className="spinnerSmall mt-4"></div>
              ) : (
                <button onClick={handleAccessStripePortal} className="font-opensans text-white px-3 py-1 mt-4 text-md w-max font-bold rounded-md hover:scale-105 bg-navy">
                  {translations[user.websiteLanguage].change_or_cancel_subscription}
                </button>
              )}

            </div>
          ) : (
            <>
              {user.isPremium && subscription.cancel_at_period_end ? (
                <div className="flex flex-col items-center border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[420px] max-w-[90%]">
                  <div className='font-madimi text-3xl mb-4'>{translations[user.websiteLanguage].are_you_sure}</div>
                  <div className='font-opensans text-navy mb-4'>{translations[user.websiteLanguage].cancelled_subscription_access}<br /><span className='font-bold'>{formatDate(subscription.current_period_end)} (UTC)</span>.</div>
                  <div className='font-opensans text-navy mb-4'>{translations[user.websiteLanguage].delete_account_warning}</div>

                  {isDeleting ? (
                    <div className="spinnerSmall mt-4"></div>
                  ) : (
                    <button onClick={deleteAccount} className="font-opensans text-white px-3 py-1 mt-4 text-md w-max font-bold rounded-md hover:scale-105 bg-red">{translations[user.websiteLanguage].delete_my_account}</button>
                  )}

                </div>
              ) : (
                <div className="flex flex-col items-center border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[420px] max-w-[90%]">
                  <div className='font-madimi text-3xl mb-4'>{translations[user.websiteLanguage].are_you_sure}</div>
                  <div className='font-opensans text-navy mb-4'>{translations[user.websiteLanguage].if_you_really_want_to_delete}</div>

                  {isDeleting ? (
                    <div className="spinnerSmall mt-4"></div>
                  ) : (
                    <button onClick={deleteAccount} className="font-opensans text-white px-3 py-1 mt-4 text-md w-max font-bold rounded-md hover:scale-105 bg-red">{translations[user.websiteLanguage].delete_my_account}</button>
                  )}

                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[420px] max-w-[90%]">
          <div className='font-madimi text-3xl mb-4'>{translations[user.websiteLanguage].are_you_sure}</div>
          <div className='font-opensans text-navy mb-4'>{translations[user.websiteLanguage].if_you_really_want_to_delete}</div>

          {isDeleting ? (
            <div className="spinnerSmall mt-4"></div>
          ) : (
            <button onClick={deleteAccount} className="font-opensans text-white px-3 py-1 mt-4 text-md w-max font-bold rounded-md hover:scale-105 bg-red">{translations[user.websiteLanguage].delete_my_account}</button>
          )}

        </div>
      )}




    </div >
  );
}

export default DeleteAccount;
