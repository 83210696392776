import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import 'react-calendar-heatmap/dist/styles.css';
import { startOfYear, endOfYear, isSameDay, parseISO, format } from 'date-fns';
import './App.css';

function Admin() {

  const [writingPrompts, setWritingPrompts] = useState([]);
  const [speakingPrompts, setSpeakingPrompts] = useState([]);
  const [hoverOverDate, setHoverOverDate] = useState('');

  useEffect(() => {
    const fetchPrompts = async (tableName) => {
      try {
        const response = await axios.get(`https://hrnmpupnzh.execute-api.eu-west-2.amazonaws.com/GetAllWritingPrompts?tableName=${tableName}`);
        return response.data; // Make sure to parse the JSON body if it's stringified
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    };

    const fetchData = async () => {
      const writingData = await fetchPrompts('WritingPrompts_A2');
      setWritingPrompts(writingData); // Parse JSON string to array

      const speakingData = await fetchPrompts('SpeakingPrompts_B1');
      setSpeakingPrompts(speakingData); // Parse JSON string to array
    };

    fetchData();
  }, []);

  // Function to map prompts to dates ignoring the year
  const createHeatmapValues = (items) => {
    const dateMap = new Map();

    items.forEach(prompt => {
      // Check if the prompt has a valid date
      if (prompt.date) {
        // Extract the month and day from the date
        // const [, month, day] = prompt.date.split('-');

        let year, month, day;

        const parts = prompt.date.split('-');
        if (parts.length === 2) {
          // If only month and day are provided
          [month, day] = parts;
          // year = '2024'; // Default year added
        } else if (parts.length === 3) {
          // If year, month, and day are provided
          [year, month, day] = parts;
        } else {
          // If the date format is unexpected, log an error or handle accordingly
          console.error('Unexpected date format:', prompt.date);
          return; // Skip this iteration
        }

        const key = `${month}-${day}`;

        // Update the count for the month-day key
        dateMap.set(key, (dateMap.get(key) || 0) + 1);
      }
    });

    // Convert the map to an array of { date, count } objects
    return Array.from(dateMap, ([date, count]) => ({
      date: `2024-${date}`, // We use a fixed year (2024) for all entries
      count,
    }));
  };


  const heatmapWritingPromptValues = createHeatmapValues(writingPrompts);
  const heatmapSpeakingPromptValues = createHeatmapValues(speakingPrompts);

  const getTooltipDataAttrs = (value) => {
    // Ensure that value.date is a string and not null
    if (value && value.date) {
      return { 'data-tip': `Prompt on ${format(parseISO(value.date), 'MMMM do')}` };
    }
    return { 'data-tip': '' };
  };


  // Check if the value date is today
  const isToday = (date) => {
    return date && isSameDay(new Date(), parseISO(date));
  };

  return (
    <div className='flex flex-col mt-28 w-[1000px]'>

      <div className='flex flex-row mb-12'>
        <select name="language" id="language" className='mr-12 p-3 w-36 bg-navy text-white rounded-md'>
          <option value="english">english</option>
          <option value="french">french</option>
          <option value="spanish">spanish</option>
          <option value="german">german</option>
          <option value="russian">russian</option>
        </select>

        <select name="level" id="level" className='mr-12 p-3 w-36 bg-navy text-white rounded-md'>
          <option value="beginner">beginner</option>
          <option value="intermediate">intermediate</option>
          <option value="proficient">proficient</option>
        </select>

        <button className='mr-12 p-3 w-36 bg-navy text-white rounded-md'>Fetch</button>
      </div>

      <div className='font-opensans text-navy text-2xl font-bold mb-4'>Vocabulary Size</div>
      <div className='mb-8 text-xl opacity-60'>248 words</div>

      <div className='font-opensans text-navy text-2xl font-bold mb-4'>Writing Prompts <span className='ml-8 text-xl opacity-60'>{hoverOverDate}</span></div>
      <CalendarHeatmap
        startDate={startOfYear(new Date())}
        endDate={endOfYear(new Date())}
        values={heatmapWritingPromptValues}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return 'color-empty';
          }
          const todayClass = isToday(value.date) ? ' today' : '';
          return `color-github-${value.count}${todayClass}`;
        }}
        tooltipDataAttrs={getTooltipDataAttrs}
        showWeekdayLabels={true}
        onMouseOver={(event, value) => setHoverOverDate(value == null ? '' : value.date)}
        onMouseLeave={(event, value) => setHoverOverDate('')}
      />


      <div className='font-opensans text-navy text-2xl font-bold mb-4'>Speaking Prompts <span className='ml-8 text-xl opacity-60'>{hoverOverDate}</span></div>
      <CalendarHeatmap
        startDate={startOfYear(new Date())}
        endDate={endOfYear(new Date())}
        values={heatmapSpeakingPromptValues}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return 'color-empty';
          }
          const todayClass = isToday(value.date) ? ' today' : '';
          return `color-github-${value.count}${todayClass}`;
        }}
        tooltipDataAttrs={getTooltipDataAttrs}
        showWeekdayLabels={true}
        onMouseOver={(event, value) => setHoverOverDate(value == null ? '' : value.date)}
        onMouseLeave={(event, value) => setHoverOverDate('')}
      />


      {/* <div className='font-opensans text-navy text-2xl font-bold mb-4'>Listening Audio <span className='ml-8 text-xl opacity-60'>{hoverOverDate}</span></div>
      <CalendarHeatmap
        startDate={startOfYear(new Date())}
        endDate={endOfYear(new Date())}
        values={heatmapValues}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return 'color-empty';
          }
          const todayClass = isToday(value.date) ? ' today' : '';
          return `color-github-${value.count}${todayClass}`;
        }}
        tooltipDataAttrs={getTooltipDataAttrs}
        showWeekdayLabels={true}
        onMouseOver={(event, value) => setHoverOverDate(value == null ? '' : value.date)}
        onMouseLeave={(event, value) => setHoverOverDate('')}
      /> */}


      {/* <div className='font-opensans text-navy text-2xl font-bold mb-4'>Reading Texts <span className='ml-8 text-xl opacity-60'>{hoverOverDate}</span></div>
      <CalendarHeatmap
        startDate={startOfYear(new Date())}
        endDate={endOfYear(new Date())}
        values={heatmapValues}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return 'color-empty';
          }
          const todayClass = isToday(value.date) ? ' today' : '';
          return `color-github-${value.count}${todayClass}`;
        }}
        tooltipDataAttrs={getTooltipDataAttrs}
        showWeekdayLabels={true}
        onMouseOver={(event, value) => setHoverOverDate(value == null ? '' : value.date)}
        onMouseLeave={(event, value) => setHoverOverDate('')}
      /> */}


      {/* <div className='font-opensans text-navy text-2xl font-bold mb-4'>Culture Texts <span className='ml-8 text-xl opacity-60'>{hoverOverDate}</span></div>
      <CalendarHeatmap
        startDate={startOfYear(new Date())}
        endDate={endOfYear(new Date())}
        values={heatmapValues}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return 'color-empty';
          }
          const todayClass = isToday(value.date) ? ' today' : '';
          return `color-github-${value.count}${todayClass}`;
        }}
        tooltipDataAttrs={getTooltipDataAttrs}
        showWeekdayLabels={true}
        onMouseOver={(event, value) => setHoverOverDate(value == null ? '' : value.date)}
        onMouseLeave={(event, value) => setHoverOverDate('')}
      /> */}



      <style jsx>{`
      .react-calendar-heatmap .color-empty {
        fill: #38383820;
      }
      .react-calendar-heatmap rect.today {
        stroke: #2b2d42;
        stroke-width: 2;
      }
    `}</style>
    </div>

  );
};

export default Admin;
