import '../App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Header from '../Components/Header';
import Settings from '../SettingsAccount/Settings';
import DeleteAccount from '../SettingsAccount/DeleteAccount';
import FAQ from '../SettingsAccount/FAQ';
import Today from '../BentoBox/Today';
import Vocabulary from '../BentoBox/Vocabulary';
import Listening from '../BentoBox/Listening';
import Reading from '../BentoBox/Reading';
import Speaking from '../BentoBox/Speaking';
import Writing from '../BentoBox/Writing';
import Culture from '../BentoBox/Culture';
import ChooseWebLanguage from '../Onboarding/ChooseWebLanguage';
import ChooseLanguage from '../Onboarding/ChooseLanguage';
import ChooseLevel from '../Onboarding/ChooseLevel';
import Subscribe from '../Onboarding/Subscribe';
import Admin from '../Admin';
import Error from '../Error';
import TermsOfService from '../SettingsAccount/TermsOfService';
import PrivacyPolicy from '../SettingsAccount/PrivacyPolicy';

import Verify from '../Verify';

function AppComponentWithRoutes({ user, setUser, serverDateTime }) {
  const location = useLocation();

  const [websiteLanguage, setWebsiteLanguage] = useState('en');

  return (
    <>
      <Header signedIn={true} user={user} websiteLanguage={websiteLanguage} setWebsiteLanguage={setWebsiteLanguage} serverDateTime={serverDateTime} />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={800}>
          <div className="flex items-center flex-col h-full w-full">
            <Routes location={location}>
              <Route path="/" element={<Today user={user} serverDateTime={serverDateTime} />} />
              <Route path="/settings" element={<Settings user={user} />} />
              <Route path="/vocabulary" element={<Vocabulary user={user} serverDateTime={serverDateTime} />} />
              <Route path="/listening" element={<Listening user={user} serverDateTime={serverDateTime} />} />
              <Route path="/reading" element={<Reading user={user} serverDateTime={serverDateTime} />} />
              <Route path="/speaking" element={<Speaking user={user} serverDateTime={serverDateTime} />} />
              <Route path="/writing" element={<Writing user={user} serverDateTime={serverDateTime} />} />
              <Route path="/culture" element={<Culture user={user} serverDateTime={serverDateTime} />} />
              <Route path="/userlanguage" element={<ChooseWebLanguage user={user} setUser={setUser} />} />
              <Route path="/chooselanguage" element={<ChooseLanguage user={user} setUser={setUser} />} />
              <Route path="/chooselevel" element={<ChooseLevel user={user} setUser={setUser} />} />
              <Route path="/subscribe" element={<Subscribe user={user} setUser={setUser} />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/deleteaccount" element={<DeleteAccount user={user} />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/error" element={<Error />} />
              <Route path="/termsofservice" element={<TermsOfService />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />

              <Route path="/verify" element={<Verify />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
}

export default AppComponentWithRoutes;