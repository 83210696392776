import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import './../Home/Home.css';
import './../App.css';
import translations from '../Localisation/Subscribe.json';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OtacgJYVWdaEBPsh6I9pu3mWyYenx2YqG4WgeZI03e4g9yoE8UfvNKbiqEgywHUKgBD0e2O34OhEA3vacAIiDf500OeFmYRlw');

const languageLevels = [
  { name: 'Beginner', imageFileName: 'seedling.svg', description: 'Simpler vocabulary, shorter audio clips and reading texts' },
  { name: 'Intermediate', imageFileName: 'potted_plant.svg', description: 'More complex vocabulary and grammar, longer audio clips and texts' },
  { name: 'Proficient', imageFileName: 'evergreen.svg', description: 'Understanding approaching that of a native speaker' },
];


function Subscribe({ user, setUser }) {
  const navigate = useNavigate();

  const handleSelect = async () => {

    try {
      const fetchedUser = await manageUser(user.uid);
      console.log(fetchedUser);
      setUser(fetchedUser)

      if (fetchedUser.learningLanguage == null) {
        navigate("/chooselanguage");
      } else if (fetchedUser.languageLevel == null) {
        navigate("/chooselevel");
      } else if (fetchedUser.setOnboardingSubscription == null) {
        navigate("/subscribe");
      } else {
        navigate("/");
      }
    } catch (error) {
      // Handle error from manageUser, perhaps show an error message to the user
      console.error('Error managing user:', error);
      navigate("/error");
    }
  };

  const manageUser = async (uid) => {
    console.log(uid);

    try {
      const response = await axios({
        method: 'put',
        url: 'https://qygqv9ukob.execute-api.eu-west-2.amazonaws.com/ManageUser',
        data: {
          uid: uid,
          setOnboardingSubscription: true
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log(response);
      return response.data; // This will return the data from the response

    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be handled where the function is called
      navigate("/error");
    }
  };


  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const createCheckoutSession = async (priceId) => {
    setIsLoadingStripe(true)
    await manageUser(user.uid);
    const stripe = await stripePromise;
    if (user) {
      const response = await fetch('https://j9pipkwwql.execute-api.eu-west-2.amazonaws.com/default/CreateStripeCheckoutSession', {
        method: 'POST',
        body: JSON.stringify({ uid: user.uid, priceId: priceId }),
      });
      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } else {
      console.log("no user")
      // Handle the case where there is no user logged in
    }
  };




  return (
    <div className='flex flex-col mx-6 mt-24 w-[896px] max-w-[90vw]'>
      <h2 className='font-opensans text-3xl font-bold mb-0 md:mb-8 text-center'>{translations[user.websiteLanguage].which_features}</h2>

      <div className='flex flex-col md:flex-row justify-between mt-8'>
        <div className='flex flex-col items-center relative w-[368px] max-w-[90vw] bg-white border-4 rounded-2xl mr-8 mb-4 md:mb-0'>
          <h3 className={`${(user.websiteLanguage == 'vi') || user.websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[user.websiteLanguage].free}</h3>

          <p className='font-opensans mt-4 block md:hidden'>{translations[user.websiteLanguage].vocabulary},&nbsp; {translations[user.websiteLanguage].listening},&nbsp; {translations[user.websiteLanguage].reading}</p>

          <div className='flex hidden md:block text-center'>
            <p className='font-opensans mt-4'>{translations[user.websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[user.websiteLanguage].listening}</p>
            <p className='font-opensans mt-4'>{translations[user.websiteLanguage].reading}</p>
            <p className='font-opensans mt-4'>&nbsp;</p>
            <p className='font-opensans mt-4'>&nbsp;</p>
            <p className='font-opensans my-4'>&nbsp;</p>
          </div>

          <button onClick={handleSelect} disabled={isLoadingStripe} className={`${(user.websiteLanguage == 'vi') || user.websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-white px-4 py-2 mt-4 mb-8 text-xl rounded-md hover:scale-105 bg-navy`}>{translations[user.websiteLanguage].choose}</button>
        </div>

        <div className='flex flex-col items-center relative w-[420px] max-w-[90vw] bg-white border-orange border-4 rounded-2xl ml-0 md:ml-8 mb-4 md:mb-0'>
          <h3 className={`${(user.websiteLanguage == 'vi') || user.websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[user.websiteLanguage].premium}</h3>

          <p className='font-opensans mt-4'>{translations[user.websiteLanguage].vocabulary}</p>
          <p className='font-opensans mt-4'>{translations[user.websiteLanguage].listening}</p>
          <p className='font-opensans mt-4'>{translations[user.websiteLanguage].reading}</p>
          <p className='font-opensans mt-4'>{translations[user.websiteLanguage].speaking} <span className={`${(user.websiteLanguage == 'vi') || user.websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[user.websiteLanguage].personalized_feedback}</span></p>
          <p className='font-opensans mt-4'>{translations[user.websiteLanguage].writing} <span className={`${(user.websiteLanguage == 'vi') || user.websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[user.websiteLanguage].personalized_feedback}</span></p>
          <p className='font-opensans my-4'>{translations[user.websiteLanguage].culture}</p>

          {isLoadingStripe ? (
            <div className='spinnerSmall mt-8'></div>
          ) : (
            <div className='flex flex-row items-center justify-center mt-8 mb-8'>


              {/* <button onClick={() => createCheckoutSession("price_1OtskrJYVWdaEBPspz5raNZ3")} className="font-madimi px-4 py-2 mx-4 text-xl rounded-md hover:scale-105 animated-gradient-bg text-white">$0 test</button> */}

              <button onClick={() => createCheckoutSession("price_1P8QxbJYVWdaEBPsGfIHFSgr")} className="font-madimi px-4 py-2 mx-4 text-xl rounded-md hover:scale-105 animated-gradient-bg text-white">{translations[user.websiteLanguage].per_month}</button>

              <div className='relative'>
                <div className={`${(user.websiteLanguage == 'vi') ? 'font-protestStrike' : (user.websiteLanguage == 'zh') || (user.websiteLanguage == 'ja') || (user.websiteLanguage == 'ko') || (user.websiteLanguage == 'ru') || (user.websiteLanguage == 'ar')  || (user.websiteLanguage == 'hi') ? 'font-bold' : 'font-lilita'} absolute w-max bg-orange top-0 left-1/2 -translate-x-1/2 -translate-y-[calc(50%+8px)] z-20 px-3 py-1 rounded-md text-sm`}>
                  <p>{translations[user.websiteLanguage].discount_notice}</p>
                </div>
                <button onClick={() => createCheckoutSession("price_1P8Qz2JYVWdaEBPsLNbVSfvx")} className="font-madimi px-4 py-2 mx-4 text-xl z-10 rounded-md hover:scale-105 animated-gradient-bg text-white">{translations[user.websiteLanguage].per_year}</button>
              </div>

            </div>
          )}
        </div>

      </div >

    </div >
  );
}

export default Subscribe;
