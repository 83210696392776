import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

function Verify() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);  // Added to track loading state

  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const [textIndex, setTextIndex] = useState(0);

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      delimiter: '~',
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  return (
    <div className="flex flex-col items-center font-opensans mt-24">

      {/* <button onClick={downloadCSV}>Download CSV</button> */}

      <input
        type="file"
        name="file"
        onChange={changeHandler}
        accept=".csv"
        style={{ display: "block", margin: "10px auto" }}
      />

      <div className='flex flex-row items-center w-[95vw] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/robot.svg`}
          alt='an image of a globe to represent culture'
        />
        <h2 className='font-madimi text-2xl'><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>Verification</span></h2>
      </div>
      {/* <h2 className='font-madimi text-2xl w-[896px] max-w-[90vw] font-bold'>Происхождение кириллицы</h2>
      <h2 className='font-madimi text-xl opacity-60 w-[896px] max-w-[90vw]'>The origins of the Cyrillic alphabet</h2> */}


      {parsedData.length > 0 && (
        <>

          <div className='flex flex-row items-center mb-2'>
            <button
              onClick={() => setTextIndex(prevIndex => prevIndex - 3)}
              className="font-madimi bg-navy text-white px-4 py-2 text-xl rounded-md hover:scale-105 disabled:opacity-50"
              disabled={textIndex == 0}>
              Prev
            </button>
            <div className='text-xl mx-6 text-bold font-opensans'>{textIndex}</div>
            <button
              onClick={() => setTextIndex(prevIndex => prevIndex + 3)}
              className="font-madimi bg-navy text-white px-4 py-2 text-xl rounded-md hover:scale-105 disabled:opacity-50"
              disabled={(textIndex == parsedData.length - 3)}>
              Next
            </button>
          </div>

          <div className="flex flex-row justify-between bg-white p-6 pb-3 rounded-2xl m-3 w-[95vw]">
            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                {values[textIndex][1]}
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                {values[textIndex + 1][1]}
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                {values[textIndex + 2][1]}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between bg-white p-6 pt-0 rounded-2xl m-3 w-[95vw]">
            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex][2]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex][3]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex][4]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex][5]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex + 1][2]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex + 1][3]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex + 1][4]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex + 1][5]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex + 2][2]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex + 2][3]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex + 2][4]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex + 2][5]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div className="flex flex-row justify-between bg-white p-6 pt-0 rounded-2xl m-3 w-[95vw]">
            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex][6]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex][7]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex][8]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex][9]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex + 1][6]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex + 1][7]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex + 1][8]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex + 1][9]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex + 2][6]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex + 2][7]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex + 2][8]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex + 2][9]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="flex flex-row justify-between bg-white p-6 pt-0 rounded-2xl m-3 w-[95vw]">
            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex][10]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex][11]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex][12]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex][13]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex + 1][10]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex + 1][11]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex + 1][12]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex + 1][13]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-2xl m-3 w-[30vw]">
              <div className='leading-relaxed'>
                <div className='flex flex-row items-center mt-6'>
                  <div className='flex flex-col'>
                    <div className='font-bold'>{values[textIndex + 2][10]}</div>

                    <div className='font-bold bg-green px-1 rounded'>
                      {values[textIndex + 2][11]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded my-3' >
                      {values[textIndex + 2][12]}
                    </div>
                    <div className='bg-[#f1f1f1] px-1 rounded ' >
                      {values[textIndex + 2][13]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>







          {/* <div className='flex flex-row items-center mt-6 mb-12'>
            <button
              onClick={() => setTextIndex(prevIndex => prevIndex - 3)}
              className="font-madimi bg-navy text-white px-4 py-2 text-xl rounded-md hover:scale-105 disabled:opacity-50"
              disabled={textIndex == 0}>
              Prev
            </button>
            <div className='text-xl mx-6 text-bold font-opensans'>{textIndex}</div>
            <button
              onClick={() => setTextIndex(prevIndex => prevIndex + 3)}
              className="font-madimi bg-navy text-white px-4 py-2 text-xl rounded-md hover:scale-105 disabled:opacity-50"
              disabled={(textIndex == parsedData.length - 3)}>
              Next
            </button>
          </div> */}
        </>
      )}


    </div>
  );
}

//   return (
//     <div className='mt-24'>
//       {/* File Uploader */}
//       <input
//         type="file"
//         name="file"
//         onChange={changeHandler}
//         accept=".csv"
//         style={{ display: "block", margin: "10px auto" }}
//       />
//       <br />
//       <br />
//       {/* Table */}
//       <table>
//         <thead>
//           <tr>
//             {tableRows.map((rows, index) => {
//               return <th key={index}>{rows}</th>;
//             })}
//           </tr>
//         </thead>
//         <tbody>
//           {values.map((value, index) => {
//             return (
//               <tr key={index}>
//                 {value.map((val, i) => {
//                   return <td key={i}>{val}</td>;
//                 })}
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// }

export default Verify;
