import '../App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Header from '../Components/Header';
import Home from '../Home/Home';
import MobileHome from '../Home/MobileHome';
import SignIn from '../SettingsAccount/SignIn';
import TermsOfService from '../SettingsAccount/TermsOfService';
import PrivacyPolicy from '../SettingsAccount/PrivacyPolicy';

import Verify from '../Verify';

function UnauthenticatedAppComponent({ setUser }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/signin" && location.pathname !== "/verify" && location.pathname !== "/privacypolicy" && location.pathname !== "/termsofservice") {
      navigate("/");
    }
  }, [location, navigate]);

  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 768;

  const [websiteLanguage, setWebsiteLanguage] = useState(localStorage.getItem('websiteLanguage') || 'en');


  return (
    <div className="flex items-center flex-col h-full w-full">

      {/* <div className='fixed bottom-0 z-40 w-full bg-white border-[#ff6154] border-t-2 text-navy py-4'>
        <div className='flex flex-row items-center justify-center max-w-[1000px] mx-auto'>
          <img
            className="mr-4"
            src={`${process.env.PUBLIC_URL}/productHunt.png`}
            alt='Product Hunt badge'
          />
          <div className='w-full'>
            <div className='font-opensans text-xl font-bold'>We've launched on Product Hunt! 🥳</div>
            <div className='font-opensans text-lg mt-1 font-bold'>Get 20% all Premium plans today only with code <span className='font-robotoMono bg-[#ff6154] text-white rounded-md ml-1 px-3 py-1'>PRODUCTHUNT20</span></div>
          </div>
        </div>
      </div> */}

      <Header signedIn={false} websiteLanguage={websiteLanguage} setWebsiteLanguage={setWebsiteLanguage} />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={800}>
          <Routes location={location}>
            <Route path="/" element={isMobile ? <MobileHome websiteLanguage={websiteLanguage} /> : <Home websiteLanguage={websiteLanguage} />} />
            <Route path="/signin" element={<SignIn setUser={setUser} websiteLanguage={websiteLanguage} />} />

            <Route path="/termsofservice" element={<TermsOfService />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />

            <Route path="/verify" element={<Verify />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default UnauthenticatedAppComponent;