import React, { useState, useEffect, useRef } from 'react'; // Import React
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import './Home.css';
import './../App.css';
import translations from '../Localisation/Home.json';

function Home({ websiteLanguage }) {

  const goodbyeWords = ['goodbye', 'adiós'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);

  const [bentoLanguage, setBentoLanguage] = useState('en');

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [contentHeight1, setContentHeight1] = useState(0);
  const [contentHeight2, setContentHeight2] = useState(0);
  const [contentHeight3, setContentHeight3] = useState(0);
  const [contentHeight4, setContentHeight4] = useState(0);
  const [contentHeight5, setContentHeight5] = useState(0);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);
  const contentRef5 = useRef(null);

  useEffect(() => {
    // Update the content height when isOpen changes
    setContentHeight1(isOpen1 ? contentRef1.current.scrollHeight : 0);
    setContentHeight2(isOpen2 ? contentRef2.current.scrollHeight : 0);
    setContentHeight3(isOpen3 ? contentRef3.current.scrollHeight : 0);
    setContentHeight4(isOpen4 ? contentRef4.current.scrollHeight : 0);
    setContentHeight5(isOpen5 ? contentRef5.current.scrollHeight : 0);
  }, [isOpen1, isOpen2, isOpen3, isOpen4, isOpen5]);

  useEffect(() => {
    const currentWord = goodbyeWords[currentWordIndex];
    if (displayText === currentWord && !isCompleted) {
      setIsCompleted(true);
      setTimeout(() => {
        setDisplayText("");
        setIsCompleted(false);
      }, 3000);
      return;
    }
    if (isCompleted) return;
    const updateText = () => {
      setDisplayText(prev => currentWord.slice(0, prev.length + 1));
      if (displayText === '') {
        setCurrentWordIndex(prevIndex => (prevIndex + 1) % goodbyeWords.length);
      }
    };
    const intervalId = setInterval(updateText, 50);
    return () => clearInterval(intervalId);
  }, [currentWordIndex, displayText, isCompleted]);

  return (
    <div className="App">

      <div className="contentContainerHome mt-24">

        {/* <h2 className='font-lilita text-7xl'>{translations[websiteLanguage].dailyAI[0]}</h2> */}
        <h2 className={`${(websiteLanguage == 'vi') ? 'font-protestStrike' : (websiteLanguage == 'zh') || (websiteLanguage == 'ja') || (websiteLanguage == 'ko') || (websiteLanguage == 'ru') || (websiteLanguage == 'ar') || (websiteLanguage == 'hi') ? 'font-bold' : 'font-lilita'} text-7xl`}>{translations[websiteLanguage].dailyAI[0]} <span className='text-white animated-gradient-bg px-4 py-1 rounded-xl'>{translations[websiteLanguage].dailyAI[1]}</span> {translations[websiteLanguage].dailyAI[2]}</h2>
        <h2 className='font-opensans text-3xl mt-6 mb-8 font-bold'>{translations[websiteLanguage].practiceSkills}</h2>

        <Link to="/signin">
          <button className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans font-bold' : 'font-madimi'} text-white px-4 py-2 text-xl rounded-md hover:scale-105 bg-navy`}>{translations[websiteLanguage].getStarted}</button>
        </Link>

        <div className='flex flex-row mt-8 mb-6 items-center'>
          <div className='flex flex-col w-20 mx-8 items-center'>
            {/* <div className='flex relative'>
              <img className="w-20 mx-8 hover:scale-110 z-40 left-2" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} alt='' />
              <img className="w-20 absolute mx-8 hover:scale-110 -left-6 -top-4 z-30" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} alt='' />
            </div> */}
            <div onClick={() => setBentoLanguage("en")} className='relative hover:scale-110 cursor-pointer w-20 h-20'>
              <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
              <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
            </div>

            <p className='font-opensans text-lg font-bold'>{translations[websiteLanguage].english}</p>
          </div>

          {/* <div className='flex flex-col'>
            <img className="w-16 mx-8 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/france.svg`} alt='' />
            <p className='font-opensans text-lg font-bold'>{translations[websiteLanguage].french}</p>
          </div> */}
          <div className='flex flex-col w-20 mx-8 items-center'>
            <img onClick={() => setBentoLanguage("es")} className="w-20 hover:scale-110 cursor-pointer" src={`${process.env.PUBLIC_URL}/flags/spain.svg`} alt='' />
            <p className='font-opensans text-lg font-bold'>{translations[websiteLanguage].spanish}</p>
          </div>
          {/* <div className='flex flex-col'>
            <img className="w-16 mx-8 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/germany.svg`} alt='' />
            <p className='font-opensans text-lg font-bold'>{translations[websiteLanguage].german}</p>
          </div> */}
          {/* <div className='flex flex-col'>
            <img className="w-20 mx-8 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/russia.svg`} alt='' />
            <p className='font-opensans text-lg font-bold'>{translations[websiteLanguage].russian}</p>
          </div> */}

          {/* <div className='h-min bg-navy text-white flex flex-col items-center justify-center mx-8 p-2 px-4 rounded-md'>
            <p className='font-opensans text-lg font-bold'>Vote for new<br/>languages</p>
          </div> */}
          {/* <div className='flex flex-col'>
            <img className="w-16 mx-8 hover:scale-110" src={`${process.env.PUBLIC_URL}/flags/japan.svg`} alt='' />
            <p className='font-opensans text-lg font-bold'>{translations[websiteLanguage].japanese}</p>
          </div> */}
        </div>

        <div className='flex flex-row mb-6 space-x-6'>
          <a className='w[250px] h-[54px]' href="https://www.producthunt.com/posts/bentolingo-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-bentolingo&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=453447&theme=light" alt="bentolingo - Your&#0032;daily&#0032;AI&#0032;language&#0032;bento&#0032;box | Product Hunt" /></a>
          <a className='w[250px] h-[54px]' href="https://www.producthunt.com/posts/bentolingo-2?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-bentolingo&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=453447&theme=light&period=weekly&topic_id=204" alt="bentolingo - Your&#0032;daily&#0032;AI&#0032;language&#0032;bento&#0032;box | Product Hunt" /></a>
        </div>

        <div className="bentoBox">
          <div className="bentoBoxRow">
            <div className="bentoElement bentoElement1 bentoElementWide bentoYellow hover:cursor-default">
              <p className={`${websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[websiteLanguage].vocabulary}</p>
              <p className="bentoElementDetail">{bentoLanguage == 'en' ? '8 / 10 words correct' : '8 / 10 palabras correctas'}</p>
            </div>
            <div className="bentoElement bentoElement2 bentoElementStandard bentoNavy hover:cursor-default">
              <p className={`${websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[websiteLanguage].listening}</p>
              <p className="bentoElementDetail">{bentoLanguage == 'en' ? 'An audio clip about food and cooking' : 'Un clip de audio sobre comida y cocina'}</p>
            </div>
            <div className="bentoElement bentoElement3 bentoElementStandard bentoYellow hover:cursor-default">
              <p className={`${websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[websiteLanguage].reading}</p>
              <p className="bentoElementDetail">{bentoLanguage == 'en' ? 'A text about cats' : 'Un texto sobre gatos'}</p>
            </div>
          </div>
          <div className="bentoBoxRow">
            <div className="bentoElement bentoElement4 bentoElementStandard bentoNavy hover:cursor-default">
              <p className={`${websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[websiteLanguage].speaking}</p>
              <p className="bentoElementDetail">{bentoLanguage == 'en' ? 'What is your favorite season and why?' : '¿Cuál es tu estación favorita y por qué?'}</p>
            </div>
            <div className="bentoElement bentoElement5 bentoElementWide bentoYellow hover:cursor-default">
              <p className={`${websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[websiteLanguage].writing}</p>
              <p className="bentoElementDetail">{bentoLanguage == 'en' ? 'Describe a place you would like to visit' : 'Describe un lugar que te gustaría visitar'}</p>
            </div>
            <div className="bentoElement bentoElement6 bentoElementStandard bentoNavy hover:cursor-default">
              <p className={`${websiteLanguage == 'vi' ? 'bentoElementHeaderVi' : (websiteLanguage == 'zh' || websiteLanguage == 'ru' || websiteLanguage == 'ja' || websiteLanguage == 'ko') ? 'bentoElementHeaderBold' : 'bentoElementHeader'}`}>{translations[websiteLanguage].culture}</p>
              <p className="bentoElementDetail">{bentoLanguage == 'en' ? 'The British Royal Family' : 'Viñedos y vinificación españoles'}</p>
            </div>
          </div>

        </div>

        <div className='bentoBoxDepth'></div>


        <div className='font-opensans text-4xl font-bold mt-20'>{translations[websiteLanguage].sayToDecisionFatigue[0]} <span className='font-robotoMono text-white animated-gradient-bg px-4 py-2 rounded-xl'>{displayText}</span> {translations[websiteLanguage].sayToDecisionFatigue[1]}</div>
        <div className='font-opensans text-xl mt-12 mb-8 font-bold'><span className='font-madimi font-normal'>bentolingo</span> {translations[websiteLanguage].guidesDaily[1]}</div>

        <div className='flex flex-row items-center justify-between my-20 text-center w-[1200px] border-white border-2 bg-white bg-opacity-50 p-6 rounded-3xl overflow-hidden'>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/speechBubble.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].vocabulary}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/ear.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].listening}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/book.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].reading}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/microphone.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].speaking}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/paper_and_pencil.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].writing}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/globe.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].culture}</div>
          </div>
        </div>


        {/* <div>A bit about AI, GPT, OpenAI</div> */}
        {/* <div className='font-opensans text-4xl font-bold mt-12'>
          {translations[websiteLanguage].poweredBy[0]}
          <span className='font-robotoMono text-white animated-gradient-bg px-4 mx-2 py-2 rounded-xl'>{translations[websiteLanguage].poweredBy[1]}</span>
          {translations[websiteLanguage].poweredBy[2]}
        </div> */}
        <img
          className="w-[300px] mt-12"
          src={`${process.env.PUBLIC_URL}/powered-by-openai-badge-filled-on-dark.svg`}
        />
        <div className='font-opensans text-xl mt-4 mb-20 font-bold'>
          {translations[websiteLanguage].allContentGenerated}
        </div>


        {/* <img
          className="w-[300px]"
          src={`${process.env.PUBLIC_URL}/powered-by-openai-badge-filled-on-dark.svg`}
        /> */}




        {/* <div className='flex flex-row justify-between w-[1200px] mt-20'>
          <div className='w-[360px] h-min border-white border-2 bg-white bg-opacity-50 p-4 rounded-xl font-opensans'>
            <div className='flex flex-row items-center mb-4'>
              <img
                className="w-12 mr-4 rounded-full"
                src={`${process.env.PUBLIC_URL}/trent_kennelly.png`}
                alt='an image of _'
              />
              <div>@trent_kennelly</div>
            </div>
            <div className='text-left'>Very much needed! I learned a new language through the normal pain of living and struggling in a new place. But if I had this I think it would have helped SIGNIFICANTLY! Thanks so much!</div>
          </div>

          <div className='w-[360px] h-min border-white border-2 bg-white bg-opacity-50 p-4 rounded-xl font-opensans'>
            <div className='flex flex-row items-center mb-4'>
              <img
                className="w-12 mr-4 rounded-full"
                src={`${process.env.PUBLIC_URL}/aman_wen.png`}
                alt='an image of _'
              />
              <div>@aman_wen</div>
            </div>
            <div className='text-left'>nice, it's like having a language coach in your pocket. I'm all in for turning daily chaos into a structured learning feast!</div>
          </div>

          <div className='w-[360px] h-min border-white border-2 bg-white bg-opacity-50 p-4 rounded-xl font-opensans'>
            <div className='flex flex-row items-center mb-4'>
              <img
                className="w-12 mr-4 rounded-full"
                src={`${process.env.PUBLIC_URL}/profile_dalat.jpg`}
                alt='an image of _'
              />
              <div>Joseph Malam</div>
            </div>
            <div className='text-left'>Probably the best thing I've ever seen!</div>
          </div>
        </div> */}



        {/* <div className='font-opensans text-4xl font-bold mt-12'>Learn at <span className='font-robotoMono text-white animated-gradient-bg px-4 py-2 rounded-xl'>your level!</span></div>
        <div className='font-opensans text-xl mt-12 mb-2 font-bold'>
          Choose from 3 difficulties
        </div> */}

        {/* <div className='flex flex-row items-center justify-between mt-12 mb-20 text-center w-[600px] border-white border-2 bg-white bg-opacity-50 p-6 rounded-3xl overflow-hidden'>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/seedling.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].beginner}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/potted_plant.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].intermediate}</div>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className="w-16 mb-4"
              src={`${process.env.PUBLIC_URL}/evergreen.svg`}
              alt='an image of _'
            />
            <div className='font-opensans text-xl font-bold'>{translations[websiteLanguage].proficient}</div>
          </div>
        </div> */}


        <div className='flex flex-row justify-between w-[1200px] mt-24'>
          <div className='relative w-[368px] bg-white border-4 rounded-2xl'>
            <h3 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[websiteLanguage].free}</h3>

            <p className='font-opensans mt-4'>{translations[websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].listening}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].reading}</p>
            <span className='flex flex-row items-center justify-center mt-4'><div className='flex items-center justify-center mr-2 bg-red text-white rounded-full h-[1rem] w-[1rem] text-xs'>✘</div><span className='font-opensans text-paleGrey line-through'>{translations[websiteLanguage].speaking}</span></span>
            <span className='flex flex-row items-center justify-center mt-4'><div className='flex items-center justify-center mr-2 bg-red text-white rounded-full h-[1rem] w-[1rem] text-xs'>✘</div><span className='font-opensans text-paleGrey line-through'>{translations[websiteLanguage].writing}</span></span>
            <span className='flex flex-row items-center justify-center mt-4'><div className='flex items-center justify-center mr-2 bg-red text-white rounded-full h-[1rem] w-[1rem] text-xs'>✘</div><span className='font-opensans text-paleGrey line-through'>{translations[websiteLanguage].culture}</span></span>
          </div>

          <div className='relative w-[368px] bg-white border-orange border-4 rounded-2xl'>
            <div className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans' : 'font-madimi'} w-max absolute bg-orange top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-2 rounded-lg`}>
              <p>{translations[websiteLanguage].lessThanADay}</p>
            </div>
            <h3 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[websiteLanguage].premiumMonthly}</h3>

            <p className='font-opensans mt-4'>{translations[websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].listening}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].reading}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].speaking} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].writing} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans my-4'>{translations[websiteLanguage].culture}</p>
          </div>

          <div className='relative w-[368px] bg-white border-4 rounded-2xl'>
            <div className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans' : 'font-madimi'} absolute w-max animated-gradient-bg text-white top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-2 rounded-lg`}>
              <p>{translations[websiteLanguage].fiftyPercentOff}</p>
            </div>
            <h3 className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} text-2xl mt-8`}>{translations[websiteLanguage].premiumYearly}</h3>

            <p className='font-opensans mt-4'>{translations[websiteLanguage].vocabulary}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].listening}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].reading}</p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].speaking} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans mt-4'>{translations[websiteLanguage].writing} <span className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-protestStrike' : 'font-madimi'} ml-2 text-sm rounded-lg bg-navy text-white py-1 px-2`}>{translations[websiteLanguage].personalisedFeedbackByAI}</span></p>
            <p className='font-opensans my-4'>{translations[websiteLanguage].culture}</p>
          </div>
        </div>

        <Link to="/signin" className='my-20'>
          <button className={`${(websiteLanguage == 'vi') || websiteLanguage == '' ? 'font-opensans font-bold' : 'font-madimi'} text-white px-4 py-2 text-xl rounded-md hover:scale-105 bg-navy`}>{translations[websiteLanguage].getStarted}</button>
        </Link>


        <div className='font-opensans my-20 text-sm opacity-70'>
          <div><span className='font-madimi font-normal'>{translations[websiteLanguage].bentolingoPoweredBy[0]}</span> {translations[websiteLanguage].bentolingoPoweredBy[1]}</div>
          <div className='mt-4'>&copy; <span className='font-madimi font-normal'>bentolingo</span> 2024</div>
        </div>


      </div>

    </div>
  );
}

export default Home;
