import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import translations from '../Localisation/Settings.json';
import difficulties from '../Localisation/Difficulties.json';


import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51OtacgJYVWdaEBPsh6I9pu3mWyYenx2YqG4WgeZI03e4g9yoE8UfvNKbiqEgywHUKgBD0e2O34OhEA3vacAIiDf500OeFmYRlw');

function Settings({ user }) {

  const navigate = useNavigate();

  const languages = [
    { iso: 'en', name: 'English', flagFileName: 'uk.svg' },
    { iso: 'fr', name: 'French', flagFileName: 'france.svg' },
    { iso: 'es', name: 'Spanish', flagFileName: 'spain.svg' },
    { iso: 'de', name: 'German', flagFileName: 'germany.svg' },
    { iso: 'ru', name: 'Russian', flagFileName: 'russia.svg' },
    { iso: 'zh', name: 'Japanese', flagFileName: 'japan.svg' },
  ]
  const userLanguageFlag = languages.find(lang => lang.iso === user.learningLanguage)?.flagFileName || 'uk.svg';
  const userLanguageFullName = languages.find(lang => lang.iso === user.learningLanguage)?.name || 'English';

  const userLanguages = [
    { iso: 'en', name: 'English', flagName: 'uk.svg' },
    { iso: 'zh', name: '普通话', flagName: 'china.svg' },
    { iso: 'es', name: 'Español', flagName: 'spain.svg' },
    { iso: 'hi', name: 'हिन्दी', flagName: 'india.svg' },
    { iso: 'ar', name: 'العربية', flagName: 'arabic.svg' },
    { iso: 'pt', name: 'Português', flagName: 'portugal.svg' },
    { iso: 'ru', name: 'Русский', flagName: 'russia.svg' },
    { iso: 'ja', name: '日本語', flagName: 'japan.svg' },
    { iso: 'fr', name: 'Français', flagName: 'france.svg' },
    { iso: 'de', name: 'Deutsch', flagName: 'germany.svg' },
    { iso: 'ko', name: '한국어', flagName: 'korea.svg' },
    { iso: 'tr', name: 'Türkçe', flagName: 'turkey.svg' },
    { iso: 'vi', name: 'Tiếng Việt', flagName: 'vietnam.svg' },
  ]
  const websiteLanguageFlag = userLanguages.find(lang => lang.iso === user.websiteLanguage)?.flagName || 'uk.svg';
  const websiteLanguageFullName = userLanguages.find(lang => lang.iso === user.websiteLanguage)?.name || 'English';

  const [inputValue, setInputValue] = useState('');

  // Function to handle input change
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    // Check if the input value exceeds 500 characters
    if (newValue.length <= 500) {
      setInputValue(newValue);
    }
  };

  const [isLoadingStripe, setIsLoadingStripe] = useState(false);
  const createCheckoutSession = async () => {
    setIsLoadingStripe(true)
    const stripe = await stripePromise;
    try {
      if (user) {
        const uid = user.uid;
        const response = await fetch('https://j9pipkwwql.execute-api.eu-west-2.amazonaws.com/default/CreateStripeCheckoutSession', {
          method: 'POST',
          body: JSON.stringify({ uid: uid }),
        });
        const session = await response.json();

        const result = await stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });

        if (result.error) {
          console.error(result.error.message);
        }
      } else {
        console.log("no user")
        // Handle the case where there is no user logged in
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      navigate("/error");
      // Optionally, handle the error state here
    } finally {
      setIsLoadingStripe(false); // End loading regardless of the result
    }
  };

  const [isLoadingStripePortal, setIsLoadingStripePortal] = useState(false);
  const handleAccessStripePortal = async () => {
    setIsLoadingStripePortal(true)
    try {
      const response = await fetch('https://7jhvbcfjn9.execute-api.eu-west-2.amazonaws.com/CreateStripePortalSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId: user.customerId }),
      });

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error accessing Stripe portal:', error);
      navigate("/error");
      // Handle error here
    }
  };


  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    const fetchSubscription = async () => {
      const response = await fetch(`https://j1777qsehg.execute-api.eu-west-2.amazonaws.com/GetUserSubscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId: user.customerId }),
      });
      if (response.ok) {
        const data = await response.json();
        setSubscription(data[0]); // Assuming you want the first subscription record
      } else {
        console.error('Failed to fetch subscription');
      }
    };

    fetchSubscription();
  }, []);

  function formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toUTCString(); // Converts to a string with the format "Mon, 03 Jul 2006 21:44:38 GMT"
  }


  return (

    <div className="flex flex-col items-center font-opensans mt-24 w-screen">

      <div className='flex flex-row items-center w-[896px] max-w-[90%] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/cog.svg`}
          alt='an image of a cog to represent settings'
        />
        <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].settings}</span></h2>
      </div>

      <div className="flex flex-col border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        <div className="flex flex-row items-center justify-between md:justify-start">

          <div className='flex flex-row'>
            {userLanguageFlag == 'uk.svg' ? (
              <>
                <div className='relative mr-2' style={{ height: '1.5rem', width: '1.5rem' }}>
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                </div>
              </>
            ) : (
              <img className="w-6 mr-2" src={`${process.env.PUBLIC_URL}/flags/${userLanguageFlag}`} />
            )}

            <div className="text-lg sm:text-xl">{translations[user.websiteLanguage].learning_language} <span className='font-bold'>{userLanguageFullName}</span></div>
          </div>

          <Link to="/chooselanguage" className="no-underline">
            <button className="font-opensans text-white px-3 py-1 ml-4 text-md font-bold rounded-md hover:scale-105 bg-navy">{translations[user.websiteLanguage].change}</button>
          </Link>
        </div>

        <div className="flex flex-row items-center mt-4 justify-between md:justify-start">

          <div className='flex flex-row'>
            {websiteLanguageFlag == 'uk.svg' ? (
              <>
                <div className='min-w-[1.5rem] min-h-[1.5rem] relative mr-2'>
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/unitedstates.svg`} style={{ clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0 100%)' }} alt='' />
                  <img className="absolute top-0 left-0 w-full h-full" src={`${process.env.PUBLIC_URL}/flags/uk.svg`} style={{ clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)' }} alt='' />
                </div>
              </>
            ) : (
              <img className="w-6 mr-2" src={`${process.env.PUBLIC_URL}/flags/${websiteLanguageFlag}`} />
            )}

            <div className="text-lg sm:text-xl">{translations[user.websiteLanguage].website_language} <span className='font-bold'>{websiteLanguageFullName}</span></div>
          </div>
          <Link to="/userlanguage" className="no-underline">
            <button className="font-opensans text-white px-3 py-1 ml-4 text-md font-bold rounded-md hover:scale-105 bg-navy">{translations[user.websiteLanguage].change}</button>
          </Link>
        </div>

        <div className="flex flex-row items-center mt-4 justify-between md:justify-start">
          <div className='flex flex-row'>
            <img
              className="w-6 mr-2"
              src={`${process.env.PUBLIC_URL}/${user.languageLevel.toLowerCase() === 'beginner'
                ? 'seedling'
                : user.languageLevel.toLowerCase() === 'proficient'
                  ? 'evergreen'
                  : 'potted_plant'
                }.svg`}
              alt={`an image of a ${user.languageLevel.toLowerCase() === 'beginner'
                ? 'seedling to represent beginner'
                : user.languageLevel.toLowerCase() === 'proficient'
                  ? 'evergreen tree to represent proficient'
                  : 'potted plant to represent intermediate'
                } language level`}
            />

            <div className="text-lg sm:text-xl">
              {translations[user.websiteLanguage].my_level}

              <span className='font-bold'>

                {user.languageLevel.toLowerCase() == "beginner" ? (
                  <> {difficulties[user.websiteLanguage].beginner.toLowerCase()}</>
                ) : (
                  <>
                    {
                      user.languageLevel.toLowerCase() == "intermediate" ? (
                        <> {difficulties[user.websiteLanguage].intermediate.toLowerCase()}</>
                      ) : (
                        <> {difficulties[user.websiteLanguage].advanced.toLowerCase()}</>
                      )
                    }
                  </>
                )}

              </span>
            </div>
          </div>
          <Link to="/chooselevel" className="no-underline">
            <button className="font-opensans text-white px-3 py-1 ml-4 text-md font-bold rounded-md hover:scale-105 bg-navy">{translations[user.websiteLanguage].change}</button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        {!user.isPremium ? (
          isLoadingStripe ? (
            <div className="spinnerSmall"></div>
          ) : (
            <Link to='/subscribe' className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} w-max px-4 py-2 text-xl z-10 rounded-md hover:scale-105 animated-gradient-bg text-white`}>
              {translations[user.websiteLanguage].get_premium}
            </Link>
          )
        ) : (
          <>
            {subscription ? (
              <>
                <div className="text-lg sm:text-xl">
                  {subscription ? (
                    <>
                      <span className='font-bold'>
                        {subscription.cancel_at_period_end ?
                          translations[user.websiteLanguage].subscription_cancel :
                          translations[user.websiteLanguage].subscription_cancel_auto_renew}
                      </span>
                      {translations[user.websiteLanguage].on}
                      <span className='font-bold'> {formatDate(subscription.current_period_end)} (UTC)</span>
                    </>
                  ) : (
                    <div className="spinnerSmall"></div>
                  )}
                </div>


                {isLoadingStripePortal ? (
                  <div className="spinnerSmall mt-4"></div>
                ) : (
                  <button onClick={handleAccessStripePortal} className="font-opensans text-white px-3 py-1 mt-4 text-md w-max font-bold rounded-md hover:scale-105 bg-navy">
                    {translations[user.websiteLanguage].change_cancel_subscription}
                  </button>
                )}

                {/* <button onClick={handleAccessStripePortal} className="font-opensans text-white px-3 py-1 mt-4 text-md w-max font-bold rounded-md hover:scale-105 bg-navy">Change or cancel my subscription</button> */}
              </>
            ) : (
              <div className="spinnerSmall"></div>
            )}
          </>
        )}
      </div>


      <div className="flex flex-col border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        <Link to="/deleteaccount" className="no-underline w-max">
          <button className="font-opensans w-max text-white px-3 py-1 text-md w-max font-bold rounded-md hover:scale-105 bg-navy">{translations[user.websiteLanguage].delete_account}</button>
        </Link>
      </div>

      <div className="flex flex-col border-white border-2 bg-white bg-opacity-50 p-6 rounded-2xl m-3 w-[896px] max-w-[90%]">
        <Link to="/termsofservice" className='underline flex flex-row items-center font-opensans mb-1 cursor-pointer'>
          {translations[user.websiteLanguage].terms_of_service}
        </Link>
        <Link to="/privacypolicy" className='underline flex flex-row items-center font-opensans mt-1 cursor-pointer'>
          {translations[user.websiteLanguage].privacy_policy}
        </Link>
      </div>





    </div>
  );
}

export default Settings;
