import React, { useState, useEffect } from 'react';

function UTCCountdown({ serverDateTime }) {
  const [timeLeft, setTimeLeft] = useState('...');

  useEffect(() => {
    const initialServerTime = new Date(serverDateTime);
    const initialLocalTime = new Date();
    const timeOffset = initialServerTime.getTime() - initialLocalTime.getTime();

    const timer = setInterval(() => {
      const now = new Date(new Date().getTime() + timeOffset);
      const nextMidnight = new Date(now);
      nextMidnight.setUTCHours(24, 0, 0, 0);

      const diff = nextMidnight.getTime() - now.getTime();
      if (diff <= 0) {
        window.location.reload();
      } else {
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        if ((hours < 1) && (minutes < 10)) {
          setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
        } else {
          setTimeLeft(`${hours}h ${minutes}m`);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='flex flex-row border-white border-2 bg-white bg-opacity-50 text-navy font-opensans p-1 px-2 ml-4 rounded-md text-lg font-bold'>
      <img
        className="w-5 mr-2"
        src={`${process.env.PUBLIC_URL}/timerClock.svg`}
        alt=''
      />
      {timeLeft}
    </div>
  );
}

export default UTCCountdown;
