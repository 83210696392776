import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import translations from '../Localisation/Culture.json';


function Culture({ user, serverDateTime }) {

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateFromUrl = searchParams.get('date');

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingCulture, setIsUpdatingCulture] = useState(false);
  const [cultureComplete, setCultureComplete] = useState(false);

  const [targetPrompt, setTargetPrompt] = useState('');
  const [nativePrompt, setNativePrompt] = useState('');

  const [targetContent, setTargetContent] = useState('');
  const [nativeContent, setNativeContent] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      var serverDate = serverDateTime.split('T')[0];
      console.log("serverDate: " + serverDate);

      if (user && user.uid) {
        let selectedDate = serverDate;
        if (dateFromUrl) {
          if (isDateParamInFuture(dateFromUrl)) {
            navigate('/');
            return; // Prevent further execution if navigated away
          }
          selectedDate = dateFromUrl;
        }

        const monthAndYear = selectedDate.split('-')[1] + '-' + selectedDate.split('-')[2];
        await Promise.all([
          fetchUserTasks(user.uid, selectedDate),
          getCulturePrompt(monthAndYear),
          getCultureContent(monthAndYear)
        ]);
        setIsLoading(false)
      }
    };

    fetchData().catch(console.error);
  }, []);

  const isDateParamInFuture = (someDateString) => {
    const someDate = new Date(someDateString);
    const utcSomeDate = Date.UTC(someDate.getUTCFullYear(), someDate.getUTCMonth(), someDate.getUTCDate());

    const now = new Date(serverDateTime);
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

    return utcSomeDate > utcToday;
  };



  const fetchUserTasks = async (uid, dateWithYear) => {
    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid, date: dateWithYear });
      const userTasks = response.data;
      console.log("Retrieved user task:", userTasks);

      if (userTasks.culture) {
        setCultureComplete(true)
      } else {
        console.log("Not yet attempted")
        if (dateFromUrl) {
          navigate("/")
        }
      }
    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    }
  };


  const getCultureContent = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://lbh3x64r8e.execute-api.eu-west-2.amazonaws.com/GetCultureContent',
        JSON.stringify({
          date: dateToFind,
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setNativeContent(answerResponse.data[user.websiteLanguage])
      setTargetContent(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setNativeContent("Unable to fetch prompt")
      setTargetContent("Unable to fetch prompt")
    }
  };


  const getCulturePrompt = async (dateToFind) => {
    try {
      const answerResponse = await axios.post('https://u3d0c8s1mi.execute-api.eu-west-2.amazonaws.com/GetPrompt',
        JSON.stringify({
          task: 'Culture',
          date: dateToFind,
          target: user.learningLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setNativePrompt(answerResponse.data[user.websiteLanguage])
      setTargetPrompt(answerResponse.data[user.learningLanguage])

    } catch (error) {
      console.error('Error fetching data:', error);
      setNativePrompt("Unable to fetch prompt")
      setTargetPrompt("Unable to fetch prompt")
    }
  };


  const markCultureAsComplete = async () => {
    setIsUpdatingCulture(true)
    try {
      await axios.post('https://q6dgxctfxj.execute-api.eu-west-2.amazonaws.com/MarkCultureAsCompleted', { uid: user.uid });
      console.log("Culture updated successfully.");
    } catch (error) {
      console.error("Failed to update culture:", error);
      navigate("/error");
    } finally {
      setIsUpdatingCulture(false)
      setCultureComplete(true)
    }
  };



  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-col h-screen w-screen">
        <div className="spinner"></div>
      </div>
    );
  }


  return (
    <div className="flex flex-col items-center font-opensans mt-24">

      <div className='flex flex-row items-center w-[896px] max-w-[90vw] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/globe.svg`}
          alt='an image of a globe to represent culture'
        />
        <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].culture}</span></h2>
      </div>
      <h2 className='font-opensans text-2xl w-[896px] max-w-[90vw] font-bold'>{targetPrompt}</h2>
      <h2 className='font-opensans text-xl opacity-60 w-[896px] max-w-[90vw]'>{nativePrompt}</h2>

      <div className="bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
        <div className='leading-relaxed'>{targetContent}</div>
      </div>

      <div className="bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
        <div className='leading-relaxed'>{nativeContent}</div>
      </div>

      {isUpdatingCulture ? (
        <div className="spinnerSmall mt-4"></div>
      ) : (
        <>
          {cultureComplete ? (
            <button className="flex items-center font-madimi bentoYellow text-white px-4 py-2 mt-4 mb-8 text-xl rounded-md">
              <span className='text-lg mr-2'>✔</span> {translations[user.websiteLanguage].complete}
            </button>
          ) : (
            <button onClick={markCultureAsComplete} className="flex items-center font-madimi bg-navy text-white px-4 py-2 mt-4 mb-8 text-xl rounded-md hover:scale-105">
              <span className='text-lg mr-2'>✔</span> {translations[user.websiteLanguage].mark_as_completed}
            </button>
          )}
        </>
      )}


    </div>
  );
}

export default Culture;
