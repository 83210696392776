import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import translations from '../Localisation/Listening.json';



function Listening({ user, serverDateTime }) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [taskComplete, setTaskComplete] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dateFromUrl = searchParams.get('date');

  const isDateParamInFuture = (someDateString) => {
    const someDate = new Date(someDateString);
    const utcSomeDate = Date.UTC(someDate.getUTCFullYear(), someDate.getUTCMonth(), someDate.getUTCDate());
    const now = new Date(serverDateTime);
    const utcToday = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    return utcSomeDate > utcToday;
  };


  useEffect(() => {
    var serverDate = serverDateTime.split('T')[0]
    if (user && user.uid) {
      if (dateFromUrl) {
        if (isDateParamInFuture(dateFromUrl)) {
          navigate('/')
        } else {
          fetchUserTasks(dateFromUrl);
        }
      } else {
        fetchUserTasks(serverDate);
      }
    }
  }, []);


  const fetchUserTasks = async (date) => {
    try {
      const response = await axios.post('https://rop7d09na4.execute-api.eu-west-2.amazonaws.com/GetUserTasksCompleted', { uid: user.uid, date: date });
      const userTasks = response.data;
      if (userTasks.listening != null) {
        setTaskComplete(true)
        var userAnswerParts = userTasks.listening.split(',')
        var level = userAnswerParts[userAnswerParts.length - 2]
        var targetLanguage = userAnswerParts[userAnswerParts.length - 1]
        setUserAnswers(userAnswerParts)
        await Promise.all([
          fetchAudio(date, level, targetLanguage),
          fetchQuestions(date, level, targetLanguage),
          fetchPrompts(date, targetLanguage)
        ]);
      } else {
        console.log("Not yet attempted")
        if (dateFromUrl) {
          navigate("/")
        }
        await Promise.all([
          fetchAudio(date, user.languageLevel, user.learningLanguage),
          fetchQuestions(date, user.languageLevel, user.learningLanguage),
          fetchPrompts(date, user.learningLanguage)
        ]);
      }
    } catch (error) {
      console.error("Error fetching user task:", error);
      navigate("/error");
    } finally {
      setIsLoading(false);
    }
  };




  const [audioSrc, setAudioSrc] = useState('');
  const fetchAudio = async (date, level, targetLanguage) => {
    try {
      let dateToFind = '';
      if (typeof searchParams !== 'undefined' && searchParams.get('date')) {
        const dateParam = searchParams.get('date');
        const dateParts = dateParam.split('-');
        if (dateParts.length >= 3) {
          dateToFind = `${dateParts[1]}-${dateParts[2]}`; // Format as MM-DD
        }
      } else {
        const dateParts = date.split('-');
        if (dateParts.length >= 3) {
          dateToFind = `${dateParts[1]}-${dateParts[2]}`; // Format as MM-DD
        }
      }
      if (!dateToFind) {
        console.error('Invalid or missing date information.');
      }
      const response = await fetch('https://mibbo3h8ul.execute-api.eu-west-2.amazonaws.com/GetListeningAudio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ targetLanguage: targetLanguage, level: level.toLowerCase(), date: dateToFind }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        setAudioSrc(base64data); // This will be your audio data as a base64 URL
      }
    } catch (err) {
      navigate("/error");
    }
  };








  const [prompt, setPrompt] = useState('');
  const [otherPrompt, setOtherPrompt] = useState('');
  const fetchPrompts = async (date, targetLanguage) => {
    try {
      var mmdd = date.split('-')[1] + '-' + date.split('-')[2]
      const answerResponse = await axios.post('https://oymtw4esd0.execute-api.eu-west-2.amazonaws.com/GetListeningPrompt',
        JSON.stringify({
          date: mmdd,
          target: targetLanguage,
          native: user.websiteLanguage,
        }), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setPrompt(answerResponse.data[user.websiteLanguage])
      setOtherPrompt(answerResponse.data[targetLanguage])
    } catch (err) {
      console.error('Failed to fetch prompt:', err.message);
      navigate("/error");
    }
  };









  const fetchQuestions = async (date, level, targetLanguage) => {
    try {
      var mmdd = date.split('-')[1] + '-' + date.split('-')[2]
      const response = await axios.post('https://5ht3jzjtcc.execute-api.eu-west-2.amazonaws.com/GetListeningQuestions', {
        date: mmdd,
        targetLanguage: targetLanguage,
        level: level
      });
      if (response.data && response.data.question1) {
        const questionsArray = ['question1', 'question2', 'question3'].map((key) => {
          const orderedOptions = [];
          const correctOption = { text: response.data[key].correct, correct: true };
          const incorrectOptions = response.data[key].incorrect.map((text) => ({ text }));
          const allOptions = [correctOption, ...incorrectOptions];
          response.data[key].order.forEach((index) => {
            orderedOptions.push(allOptions[index]);
          });
          orderedOptions.forEach((option, index) => {
            option.option = String.fromCharCode(65 + index); // Converts 0 to 'A', 1 to 'B', 2 to 'C', etc.
          });
          return {
            question: response.data[key].text,
            options: orderedOptions
          };
        });
        setQuestions(questionsArray);
      } else {
        console.log('No content found'); // Handle no content found
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      navigate("/error");
    }
  };









  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [animationState, setAnimationState] = useState('enter');
  const [userAnswers, setUserAnswers] = useState([null, null, null])

  useEffect(() => {
    if (animationState === 'leave') {
      const timeoutId = setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setAnimationState('enter');
      }, 500); // Duration of the leave animation
      return () => clearTimeout(timeoutId);
    }
  }, [animationState]);

  const handleOptionClick = (optionIndex) => {
    const isCorrect = questions[currentQuestionIndex].options[optionIndex].correct;
    const updatedQuestions = questions.map((question, qIndex) => {
      if (qIndex === currentQuestionIndex) {
        const updatedOptions = question.options.map((option, oIndex) => ({
          ...option,
          selected: oIndex === optionIndex,
          showStatus: true
        }));
        return { ...question, options: updatedOptions, answered: true };
      }
      return question;
    });

    setQuestions(updatedQuestions);

    var temp = userAnswers
    temp[currentQuestionIndex] = optionIndex
    setUserAnswers(temp)

    if (currentQuestionIndex == questions.length - 1) {
      updateUserListeningResults()
    }

    // Trigger the leave animation
    setAnimationState('leave');
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const updateUserListeningResults = async () => {

    var tempAnswers = userAnswers
    tempAnswers.push(user.languageLevel)
    tempAnswers.push(user.learningLanguage)
    setUserAnswers(tempAnswers)

    setIsUpdating(true)
    try {
      await axios.post('https://lrvabjvwpb.execute-api.eu-west-2.amazonaws.com/UpdateUserListeningResults', { uid: user.uid, userAnswers });
      console.log("Results updated successfully.");
      // Handle post-update actions here (e.g., show results, redirect, etc.)
    } catch (error) {
      console.error("Failed to update results:", error);
      navigate("/error");
    } finally {
      setIsUpdating(false)
    }
  };





  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  }

  const [width] = useWindowSize();
  const isMobile = width < 768;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-col h-screen w-screen">
        <div className="spinner"></div>
      </div>
    );
  }


  return (

    <div className="flex flex-col items-center font-opensans mt-24">


      <div className='flex flex-row items-center w-[896px] max-w-[90vw] mb-4'>
        <img
          className="w-6 mr-2"
          src={`${process.env.PUBLIC_URL}/ear.svg`}
          alt='an image of an ear to represent listening'
        />
        <h2 className={`${(user.websiteLanguage == 'vi' || user.websiteLanguage == 'zh' || user.websiteLanguage == 'ru' || user.websiteLanguage == 'ja' || user.websiteLanguage == 'ko') ? 'font-opensans font-bold' : 'font-madimi'} text-xl md:text-2xl`}><span className='text-white animated-gradient-bg px-4 py-2 mr-2 rounded-lg'>{translations[user.websiteLanguage].listening}</span></h2>
      </div>
      <h2 className='font-opensans text-2xl w-[896px] max-w-[90vw] font-bold'>{otherPrompt}</h2>
      <h2 className='font-opensans text-xl opacity-60 w-[896px] max-w-[90vw]'>{prompt}</h2>



      <div className="flex flex-col bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw]">
        <div className='flex flex-col'>
          {/* <button className="flex items-center justify-center font-opensans text-2xl font-bold text-navy bg-mediaButtonGrey w-12 h-12 mr-3 rounded-lg hover:scale-105 hover:cursor-pointer">⏵</button> */}
          <audio className='w-full' controls src={audioSrc}>Your browser does not support the audio element.</audio>

          <div className='flex flex-row items-center mt-4 font-opensans text-[#383838]'>
            <img
              className="h-[1rem] mr-2"
              src={`${process.env.PUBLIC_URL}/robot.svg`}
              alt='robot'
            />{translations[user.websiteLanguage].ai_voice}
          </div>
        </div>
      </div>



      <div className="listening-container">
        <div className={`flex flex-col bg-white p-6 rounded-2xl m-3 w-[896px] max-w-[90vw] question ${animationState}`}>
          {((questions.length > 0 && currentQuestionIndex < questions.length) && !taskComplete) ? (
            <div key={currentQuestionIndex}>
              <h2 className='text-lg font-bold'>{questions[currentQuestionIndex].question}</h2>

              {(currentQuestionIndex >= 0 && currentQuestionIndex < questions.length && questions[currentQuestionIndex]) && (
                <>
                  {questions[currentQuestionIndex].options.map((option, oIndex) => (
                    <div className='flex flex-row items-center mt-6' key={oIndex}>
                      <button
                        key={oIndex}
                        className={`flex items-center justify-center font-opensans text-xl font-bold text-white w-12 h-12 mr-3 rounded-lg hover:scale-105 hover:cursor-pointer bg-navy`}
                        onClick={() => handleOptionClick(oIndex)}
                        disabled={questions[currentQuestionIndex].answered}
                      >
                        {option.option}
                      </button>
                      {option.text}
                    </div>
                  ))}
                </>
              )}

            </div>
          ) : (
            <>
              {!isUpdating ? (
                <div>
                  {(() => {
                    const correctCount = questions.reduce((acc, question, qIndex) => {
                      const option = question.options[userAnswers[qIndex]];
                      if (!option) {
                        console.error(`Option not found for question ${qIndex} and user answer index ${userAnswers[qIndex]}`);
                        return acc; // Skip this iteration
                      }
                      const correctOption = question.options.find(o => o.correct);
                      const correctAnswerText = correctOption ? correctOption.text : "";
                      const userAnswerText = option.text;

                      return acc + (userAnswerText === correctAnswerText ? 1 : 0);
                    }, 0);


                    // Adjusting the text based on the correct count
                    const questionText = correctCount === 1 ? "question" : "questions";

                    //{translations[user.websiteLanguage].listening}
                    return <div>{translations[user.websiteLanguage].score.replace("{correctCount}", correctCount)}</div>;
                  })()}

                  {questions.map((question, qIndex) => {
                    // Ensure there's a user answer and it's within the range of available options
                    const userAnswerIndex = userAnswers[qIndex];
                    const hasValidAnswer = userAnswerIndex !== undefined && question.options[userAnswerIndex];

                    const userOption = hasValidAnswer ? question.options[userAnswerIndex] : undefined;
                    const userAnswerText = userOption ? userOption.text : "No answer provided";

                    const correctOption = question.options.find(option => option.correct);
                    const correctAnswerText = correctOption ? correctOption.text : "No correct answer";

                    return (
                      <div className='flex flex-row items-center mt-6' key={qIndex}>
                        {userAnswerText === correctAnswerText ? (
                          <div className={`flex items-center justify-center text-white ${!isMobile ? 'w-12 h-12 min-w-12 min-h-12 text-2xl' : 'w-8 h-8 min-w-8 min-h-8 text-lg'} rounded-full mr-4 bg-green`}>✔</div>
                        ) : (
                          <div className={`flex items-center justify-center text-white ${!isMobile ? 'w-12 h-12 min-w-12 min-h-12 text-2xl' : 'w-8 h-8 min-w-8 min-h-8 text-lg'} rounded-full mr-4 bg-red`}>✘</div>
                        )}
                        <div className='flex flex-col'>
                          <div className='text-lg font-bold'>{question.question}</div>
                          <div>You answered: {userAnswerText}</div>
                          <div>Correct answer: <span className='font-bold'>{correctAnswerText}</span></div>
                        </div>
                      </div>
                    );
                  })}

                </div>
              ) : (
                <>
                  <div className="spinner"></div>
                </>
              )}
            </>
          )}
        </div>
      </div>


    </div>
  );
}

export default Listening;
