export async function fetchCurrentDate(type) {
  try {
      const response = await fetch('https://g2u32cohl4.execute-api.eu-west-2.amazonaws.com/GetCurrentDateTime');
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (type == 'datetime') {
        return data
      }
      const today = data.timestamp.split('T')[0];
      if (type == 'mmdd') {
        var pieces = today.split('-')
        return pieces[1] + '-' + pieces[2]
      }
      return today;
  } catch (error) {
      console.error('Error fetching current date:', error);
      throw error;
  }
}