import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import './../Home/Home.css';
import './../App.css';
import translations from '../Localisation/ChooseLevel.json';

function ChooseLevel({ user, setUser }) {
  const navigate = useNavigate();

  const languageLevels = [
    { engName: 'Beginner', name: translations[user.websiteLanguage].beginner, imageFileName: 'seedling.svg', description: translations[user.websiteLanguage].beginner_description },
    { engName: 'Intermediate', name: translations[user.websiteLanguage].intermediate, imageFileName: 'potted_plant.svg', description: translations[user.websiteLanguage].intermediate_description },
    { engName: 'Advanced', name: translations[user.websiteLanguage].advanced, imageFileName: 'evergreen.svg', description: translations[user.websiteLanguage].advanced_description },
  ];

  const [showLoading, setShowLoading] = useState(false);

  const handleLevelSelect = async (level) => {
    setShowLoading(true);

    try {
      const response = await manageUser(user.uid, level);
      console.log(response);
      if (response) {
        setUser(response);
  
        // Check each property and navigate accordingly
        if (!response.learningLanguage) {
          navigate("/chooselanguage");
        } else if (!response.websiteLanguage) {
          navigate("/userlanguage");
        } else if (!response.languageLevel) {
          navigate("/chooselevel");
        } else if (!response.setOnboardingSubscription) {
          navigate("/subscribe");
        } else {
          navigate("/");
        }
      } else {
        // If response or response.data is not as expected, handle the case
        console.error('Unexpected response structure:', response);
        navigate("/error");
      }
    } catch (error) {
      // Handle error from manageUser, perhaps show an error message to the user
      console.error('Error managing user:', error);
      navigate("/error");
    }
  };

  const manageUser = async (uid, level) => {
    console.log(uid);

    try {
      const response = await axios({
        method: 'put',
        url: 'https://qygqv9ukob.execute-api.eu-west-2.amazonaws.com/ManageUser',
        data: {
          uid: uid,
          languageLevel: level
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });

      console.log(response);
      return response.data; // This will return the data from the response

    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be handled where the function is called
      navigate("/error");
    }
  };

  return (
    <div className='flex mx-6 min-h-[100dvh] items-center justify-center'>
      {showLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className='flex flex-col justify-center'>
          <h2 className='font-opensans text-2xl md:text-3xl font-bold mt-20 md:mt-0 mb-4 md:mb-8 text-center'>{translations[user.websiteLanguage].title}</h2>
          <div className='flex flex-col flex-wrap items-center justify-center w-[896px] max-w-[90vw] mx-auto'>
            {languageLevels.map((level) => (
              <button key={level.name} onClick={() => handleLevelSelect(level.engName)}>
                <div className='flex flex-row items-center justify-center border-white border-2 bg-white bg-opacity-50 text-navy hover:text-white my-3 md:m-4 w-full md:w-[36rem] h-36 rounded-3xl hover:cursor-pointer hover:animated-gradient-bg'>
                  <img className="w-12 md:w-16 mx-6 md:mx-10" src={`${process.env.PUBLIC_URL}/${level.imageFileName}`} alt={`${level.name} level icon`} />
                  <div className='flex flex-col items-start w-full pr-6 md:pr-10 '>
                    <div className='font-opensans text-xl font-bold'>{level.name}</div>
                    <div className='font-opensans text-md font-bold mt-2 text-left'>{level.description}</div>
                  </div>
                </div>
              </button>
            ))}
            {/* {!user.languageLevel && (
              <div className='font-opensans text-md mt-2'>You can change this at any time in Settings.</div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChooseLevel;
